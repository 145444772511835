/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum MountedMaterialStatus {
  None = 'None',
  Mounted = 'Mounted',
  Active = 'Active',
  Consumed = 'Consumed'
}
