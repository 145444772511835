/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PatternDetail } from '../../models/pattern-detail';

export interface GetProductionOrderPatternInfo$Params {
  productionOrderId: number;
}

export function getProductionOrderPatternInfo(
  http: HttpClient,
  rootUrl: string,
  params: GetProductionOrderPatternInfo$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<PatternDetail>> {
  const rb = new RequestBuilder(rootUrl, getProductionOrderPatternInfo.PATH, 'get');
  if (params) {
    rb.path('productionOrderId', params.productionOrderId, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PatternDetail>;
    })
  );
}

getProductionOrderPatternInfo.PATH = '/api/v1/PatternInfo/GetProductionOrderPatternInfo/{productionOrderId}';
