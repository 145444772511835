/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SettingEntityInfoViewModel } from '../../models/setting-entity-info-view-model';

export interface GetSettingEntityInfo$Params {
  hierarchyType: string;
  hierarchyLevel: string;
  entityId: string;
}

export function getSettingEntityInfo(
  http: HttpClient,
  rootUrl: string,
  params: GetSettingEntityInfo$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<SettingEntityInfoViewModel>> {
  const rb = new RequestBuilder(rootUrl, getSettingEntityInfo.PATH, 'get');
  if (params) {
    rb.path('hierarchyType', params.hierarchyType, {});
    rb.path('hierarchyLevel', params.hierarchyLevel, {});
    rb.path('entityId', params.entityId, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SettingEntityInfoViewModel>;
    })
  );
}

getSettingEntityInfo.PATH = '/api/v1/Settings/GetSettingEntityInfo/{hierarchyType}/{hierarchyLevel}/{entityId}';
