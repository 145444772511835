/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ClientInfoView } from '../models/client-info-view';
import { getAllClients } from '../fn/change-notifications/get-all-clients';
import { GetAllClients$Params } from '../fn/change-notifications/get-all-clients';
import { getAllTopics } from '../fn/change-notifications/get-all-topics';
import { GetAllTopics$Params } from '../fn/change-notifications/get-all-topics';
import { TopicClientInfoView } from '../models/topic-client-info-view';

@Injectable({ providedIn: 'root' })
export class ChangeNotificationsService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllClients()` */
  static readonly GetAllClientsPath = '/api/v1/ChangeNotifications/Clients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllClients()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllClients$Response(params?: GetAllClients$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ClientInfoView>>> {
    return getAllClients(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllClients$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllClients(params?: GetAllClients$Params, context?: HttpContext): Observable<Array<ClientInfoView>> {
    return this.getAllClients$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ClientInfoView>>): Array<ClientInfoView> => r.body)
    );
  }

  /** Path part for operation `getAllTopics()` */
  static readonly GetAllTopicsPath = '/api/v1/ChangeNotifications/Topics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTopics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTopics$Response(params?: GetAllTopics$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TopicClientInfoView>>> {
    return getAllTopics(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllTopics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTopics(params?: GetAllTopics$Params, context?: HttpContext): Observable<Array<TopicClientInfoView>> {
    return this.getAllTopics$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TopicClientInfoView>>): Array<TopicClientInfoView> => r.body)
    );
  }
}
