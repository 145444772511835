/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ShutdownReason } from '../../models/shutdown-reason';

export interface GetShutdownReasons$Params {}

export function getShutdownReasons(
  http: HttpClient,
  rootUrl: string,
  params?: GetShutdownReasons$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<ShutdownReason>>> {
  const rb = new RequestBuilder(rootUrl, getShutdownReasons.PATH, 'get');
  if (params) {
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ShutdownReason>>;
    })
  );
}

getShutdownReasons.PATH = '/api/v1/WorkCenter/ShutdownReasons';
