/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { backToWarehouse } from '../fn/secondary-material/back-to-warehouse';
import { BackToWarehouse$Params } from '../fn/secondary-material/back-to-warehouse';
import { consume } from '../fn/secondary-material/consume';
import { Consume$Params } from '../fn/secondary-material/consume';
import { createAndMountVirtualSecondaryContainer } from '../fn/secondary-material/create-and-mount-virtual-secondary-container';
import { CreateAndMountVirtualSecondaryContainer$Params } from '../fn/secondary-material/create-and-mount-virtual-secondary-container';
import { dismount } from '../fn/secondary-material/dismount';
import { Dismount$Params } from '../fn/secondary-material/dismount';
import { getMaterialAvailable } from '../fn/secondary-material/get-material-available';
import { GetMaterialAvailable$Params } from '../fn/secondary-material/get-material-available';
import { getMaterialAvailableForArticle } from '../fn/secondary-material/get-material-available-for-article';
import { GetMaterialAvailableForArticle$Params } from '../fn/secondary-material/get-material-available-for-article';
import { getMaterialAvailableForProductionOrder2 } from '../fn/secondary-material/get-material-available-for-production-order-2';
import { GetMaterialAvailableForProductionOrder2$Params } from '../fn/secondary-material/get-material-available-for-production-order-2';
import { getMaterialAvailableForProductionOrderAndArticle } from '../fn/secondary-material/get-material-available-for-production-order-and-article';
import { GetMaterialAvailableForProductionOrderAndArticle$Params } from '../fn/secondary-material/get-material-available-for-production-order-and-article';
import { getMountedMaterials2 } from '../fn/secondary-material/get-mounted-materials-2';
import { GetMountedMaterials2$Params } from '../fn/secondary-material/get-mounted-materials-2';
import { getNextSecondaryContainerForDismount } from '../fn/secondary-material/get-next-secondary-container-for-dismount';
import { GetNextSecondaryContainerForDismount$Params } from '../fn/secondary-material/get-next-secondary-container-for-dismount';
import { getSecondaryMaterial } from '../fn/secondary-material/get-secondary-material';
import { GetSecondaryMaterial$Params } from '../fn/secondary-material/get-secondary-material';
import { getSecondaryVirtualContainerData } from '../fn/secondary-material/get-secondary-virtual-container-data';
import { GetSecondaryVirtualContainerData$Params } from '../fn/secondary-material/get-secondary-virtual-container-data';
import { getVirtualSecondaryContainerReasons } from '../fn/secondary-material/get-virtual-secondary-container-reasons';
import { GetVirtualSecondaryContainerReasons$Params } from '../fn/secondary-material/get-virtual-secondary-container-reasons';
import { MaterialAvailableAtLocation } from '../models/material-available-at-location';
import { MountingKpiValue } from '../models/mounting-kpi-value';
import { mountSecondaryContainer } from '../fn/secondary-material/mount-secondary-container';
import { MountSecondaryContainer$Params } from '../fn/secondary-material/mount-secondary-container';
import { replaceVirtualSecondaryContainer } from '../fn/secondary-material/replace-virtual-secondary-container';
import { ReplaceVirtualSecondaryContainer$Params } from '../fn/secondary-material/replace-virtual-secondary-container';
import { SecondaryMaterial } from '../models/secondary-material';
import { SecondaryMaterialBlock } from '../models/secondary-material-block';
import { SecondaryMaterialMounted } from '../models/secondary-material-mounted';
import { setPunchingPressure } from '../fn/secondary-material/set-punching-pressure';
import { SetPunchingPressure$Params } from '../fn/secondary-material/set-punching-pressure';
import { toBeDismountedKpi } from '../fn/secondary-material/to-be-dismounted-kpi';
import { ToBeDismountedKpi$Params } from '../fn/secondary-material/to-be-dismounted-kpi';
import { VirtualContainerData } from '../models/virtual-container-data';
import { VirtualContainerReason } from '../models/virtual-container-reason';

@Injectable({ providedIn: 'root' })
export class SecondaryMaterialService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `mountSecondaryContainer()` */
  static readonly MountSecondaryContainerPath = '/api/v1/SecondaryMaterial/{workCenterId}/Mount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mountSecondaryContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mountSecondaryContainer$Response(params: MountSecondaryContainer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return mountSecondaryContainer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mountSecondaryContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mountSecondaryContainer(params: MountSecondaryContainer$Params, context?: HttpContext): Observable<void> {
    return this.mountSecondaryContainer$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `consume()` */
  static readonly ConsumePath = '/api/v1/SecondaryMaterial/{workCenterId}/Consume';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consume()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  consume$Response(params: Consume$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return consume(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consume$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  consume(params: Consume$Params, context?: HttpContext): Observable<void> {
    return this.consume$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `dismount()` */
  static readonly DismountPath = '/api/v1/SecondaryMaterial/{mountedMaterialId}/Dismount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dismount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dismount$Response(params: Dismount$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return dismount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dismount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dismount(params: Dismount$Params, context?: HttpContext): Observable<void> {
    return this.dismount$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `backToWarehouse()` */
  static readonly BackToWarehousePath = '/api/v1/SecondaryMaterial/{mountedMaterialId}/BackToWarehouse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `backToWarehouse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  backToWarehouse$Response(params: BackToWarehouse$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return backToWarehouse(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `backToWarehouse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  backToWarehouse(params: BackToWarehouse$Params, context?: HttpContext): Observable<void> {
    return this.backToWarehouse$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `createAndMountVirtualSecondaryContainer()` */
  static readonly CreateAndMountVirtualSecondaryContainerPath =
    '/api/v1/SecondaryMaterial/{workCenterId}/CreateAndMountVirtualSecondaryContainer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAndMountVirtualSecondaryContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAndMountVirtualSecondaryContainer$Response(
    params: CreateAndMountVirtualSecondaryContainer$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return createAndMountVirtualSecondaryContainer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAndMountVirtualSecondaryContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAndMountVirtualSecondaryContainer(params: CreateAndMountVirtualSecondaryContainer$Params, context?: HttpContext): Observable<void> {
    return this.createAndMountVirtualSecondaryContainer$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `replaceVirtualSecondaryContainer()` */
  static readonly ReplaceVirtualSecondaryContainerPath = '/api/v1/SecondaryMaterial/{mountedMaterialId}/ReplaceVirtualSecondaryContainer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceVirtualSecondaryContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceVirtualSecondaryContainer$Response(
    params: ReplaceVirtualSecondaryContainer$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return replaceVirtualSecondaryContainer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `replaceVirtualSecondaryContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceVirtualSecondaryContainer(params: ReplaceVirtualSecondaryContainer$Params, context?: HttpContext): Observable<void> {
    return this.replaceVirtualSecondaryContainer$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `setPunchingPressure()` */
  static readonly SetPunchingPressurePath = '/api/v1/SecondaryMaterial/{articleId}/SetPunchingPressure';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setPunchingPressure()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setPunchingPressure$Response(params: SetPunchingPressure$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setPunchingPressure(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setPunchingPressure$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setPunchingPressure(params: SetPunchingPressure$Params, context?: HttpContext): Observable<void> {
    return this.setPunchingPressure$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getSecondaryMaterial()` */
  static readonly GetSecondaryMaterialPath = '/api/v1/SecondaryMaterial/{workCenterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSecondaryMaterial()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSecondaryMaterial$Response(
    params: GetSecondaryMaterial$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<SecondaryMaterial>> {
    return getSecondaryMaterial(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSecondaryMaterial$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSecondaryMaterial(params: GetSecondaryMaterial$Params, context?: HttpContext): Observable<SecondaryMaterial> {
    return this.getSecondaryMaterial$Response(params, context).pipe(
      map((r: StrictHttpResponse<SecondaryMaterial>): SecondaryMaterial => r.body)
    );
  }

  /** Path part for operation `getMaterialAvailable()` */
  static readonly GetMaterialAvailablePath = '/api/v1/SecondaryMaterial/{workCenterId}/materialAvailable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMaterialAvailable()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailable$Response(
    params: GetMaterialAvailable$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<MaterialAvailableAtLocation>> {
    return getMaterialAvailable(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMaterialAvailable$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailable(params: GetMaterialAvailable$Params, context?: HttpContext): Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailable$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaterialAvailableAtLocation>): MaterialAvailableAtLocation => r.body)
    );
  }

  /** Path part for operation `getMaterialAvailableForArticle()` */
  static readonly GetMaterialAvailableForArticlePath = '/api/v1/SecondaryMaterial/{workCenterId}/materialAvailable/articles/{articleId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMaterialAvailableForArticle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableForArticle$Response(
    params: GetMaterialAvailableForArticle$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<MaterialAvailableAtLocation>> {
    return getMaterialAvailableForArticle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMaterialAvailableForArticle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableForArticle(
    params: GetMaterialAvailableForArticle$Params,
    context?: HttpContext
  ): Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableForArticle$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaterialAvailableAtLocation>): MaterialAvailableAtLocation => r.body)
    );
  }

  /** Path part for operation `getMaterialAvailableForProductionOrder2()` */
  static readonly GetMaterialAvailableForProductionOrder2Path =
    '/api/v1/SecondaryMaterial/{workCenterId}/productionOrders/{productionOrderId}/materialAvailable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMaterialAvailableForProductionOrder2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableForProductionOrder2$Response(
    params: GetMaterialAvailableForProductionOrder2$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<MaterialAvailableAtLocation>> {
    return getMaterialAvailableForProductionOrder2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMaterialAvailableForProductionOrder2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableForProductionOrder2(
    params: GetMaterialAvailableForProductionOrder2$Params,
    context?: HttpContext
  ): Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableForProductionOrder2$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaterialAvailableAtLocation>): MaterialAvailableAtLocation => r.body)
    );
  }

  /** Path part for operation `getMaterialAvailableForProductionOrderAndArticle()` */
  static readonly GetMaterialAvailableForProductionOrderAndArticlePath =
    '/api/v1/SecondaryMaterial/{workCenterId}/productionOrders/{productionOrderId}/materialAvailable/articles/{articleId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMaterialAvailableForProductionOrderAndArticle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableForProductionOrderAndArticle$Response(
    params: GetMaterialAvailableForProductionOrderAndArticle$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<MaterialAvailableAtLocation>> {
    return getMaterialAvailableForProductionOrderAndArticle(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMaterialAvailableForProductionOrderAndArticle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableForProductionOrderAndArticle(
    params: GetMaterialAvailableForProductionOrderAndArticle$Params,
    context?: HttpContext
  ): Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableForProductionOrderAndArticle$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaterialAvailableAtLocation>): MaterialAvailableAtLocation => r.body)
    );
  }

  /** Path part for operation `getVirtualSecondaryContainerReasons()` */
  static readonly GetVirtualSecondaryContainerReasonsPath = '/api/v1/SecondaryMaterial/VirtualSecondaryContainerReasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVirtualSecondaryContainerReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVirtualSecondaryContainerReasons$Response(
    params?: GetVirtualSecondaryContainerReasons$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<VirtualContainerReason>>> {
    return getVirtualSecondaryContainerReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVirtualSecondaryContainerReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVirtualSecondaryContainerReasons(
    params?: GetVirtualSecondaryContainerReasons$Params,
    context?: HttpContext
  ): Observable<Array<VirtualContainerReason>> {
    return this.getVirtualSecondaryContainerReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VirtualContainerReason>>): Array<VirtualContainerReason> => r.body)
    );
  }

  /** Path part for operation `getNextSecondaryContainerForDismount()` */
  static readonly GetNextSecondaryContainerForDismountPath = '/api/v1/SecondaryMaterial/{workCenterId}/NextSecondaryContainerForDismount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNextSecondaryContainerForDismount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNextSecondaryContainerForDismount$Response(
    params: GetNextSecondaryContainerForDismount$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<SecondaryMaterialMounted>> {
    return getNextSecondaryContainerForDismount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNextSecondaryContainerForDismount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNextSecondaryContainerForDismount(
    params: GetNextSecondaryContainerForDismount$Params,
    context?: HttpContext
  ): Observable<SecondaryMaterialMounted> {
    return this.getNextSecondaryContainerForDismount$Response(params, context).pipe(
      map((r: StrictHttpResponse<SecondaryMaterialMounted>): SecondaryMaterialMounted => r.body)
    );
  }

  /** Path part for operation `getSecondaryVirtualContainerData()` */
  static readonly GetSecondaryVirtualContainerDataPath = '/api/v1/SecondaryMaterial/virtualContainer/{ssccCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSecondaryVirtualContainerData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSecondaryVirtualContainerData$Response(
    params: GetSecondaryVirtualContainerData$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<VirtualContainerData>> {
    return getSecondaryVirtualContainerData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSecondaryVirtualContainerData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSecondaryVirtualContainerData(
    params: GetSecondaryVirtualContainerData$Params,
    context?: HttpContext
  ): Observable<VirtualContainerData> {
    return this.getSecondaryVirtualContainerData$Response(params, context).pipe(
      map((r: StrictHttpResponse<VirtualContainerData>): VirtualContainerData => r.body)
    );
  }

  /** Path part for operation `getMountedMaterials2()` */
  static readonly GetMountedMaterials2Path = '/api/v1/SecondaryMaterial/{workCenterId}/MountedMaterials';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMountedMaterials2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMountedMaterials2$Response(
    params: GetMountedMaterials2$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SecondaryMaterialBlock>>> {
    return getMountedMaterials2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMountedMaterials2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMountedMaterials2(params: GetMountedMaterials2$Params, context?: HttpContext): Observable<Array<SecondaryMaterialBlock>> {
    return this.getMountedMaterials2$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SecondaryMaterialBlock>>): Array<SecondaryMaterialBlock> => r.body)
    );
  }

  /** Path part for operation `toBeDismountedKpi()` */
  static readonly ToBeDismountedKpiPath = '/api/v1/SecondaryMaterial/{workCenterId}/ToBeDismountedKpi';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toBeDismountedKpi()` instead.
   *
   * This method doesn't expect any request body.
   */
  toBeDismountedKpi$Response(params: ToBeDismountedKpi$Params, context?: HttpContext): Observable<StrictHttpResponse<MountingKpiValue>> {
    return toBeDismountedKpi(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `toBeDismountedKpi$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  toBeDismountedKpi(params: ToBeDismountedKpi$Params, context?: HttpContext): Observable<MountingKpiValue> {
    return this.toBeDismountedKpi$Response(params, context).pipe(
      map((r: StrictHttpResponse<MountingKpiValue>): MountingKpiValue => r.body)
    );
  }
}
