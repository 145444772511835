/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ArticleClassification {
  Other = 'Other',
  Product = 'Product',
  Reel = 'Reel',
  Sheet = 'Sheet',
  Liquid = 'Liquid',
  Tool = 'Tool'
}
