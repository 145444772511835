/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { WorkCenterTask } from '../../models/work-center-task';
import { WorkCenterTaskStatus } from '../../models/work-center-task-status';
import { WorkCenterTaskType } from '../../models/work-center-task-type';

export interface GetByWorkCenterId$Params {
  workCenterId: number;
  TaskStatus?: WorkCenterTaskStatus | null;
  TaskType?: WorkCenterTaskType | null;
  ShowDoneTasks?: boolean;
  PageIndex?: number | null;
  PageSize?: number | null;
}

export function getByWorkCenterId(
  http: HttpClient,
  rootUrl: string,
  params: GetByWorkCenterId$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<WorkCenterTask>>> {
  const rb = new RequestBuilder(rootUrl, getByWorkCenterId.PATH, 'get');
  if (params) {
    rb.path('workCenterId', params.workCenterId, {});
    rb.query('TaskStatus', params.TaskStatus, {});
    rb.query('TaskType', params.TaskType, {});
    rb.query('ShowDoneTasks', params.ShowDoneTasks, {});
    rb.query('PageIndex', params.PageIndex, {});
    rb.query('PageSize', params.PageSize, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<WorkCenterTask>>;
    })
  );
}

getByWorkCenterId.PATH = '/api/v1/Tasks/{workCenterId}';
