/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ProductionOrderExternalInfo } from '../../models/production-order-external-info';

export interface GetProductionOrderInfoByExternalId2$Params {
  externalProductionOrderId: string;
}

export function getProductionOrderInfoByExternalId2(
  http: HttpClient,
  rootUrl: string,
  params: GetProductionOrderInfoByExternalId2$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<ProductionOrderExternalInfo>> {
  const rb = new RequestBuilder(rootUrl, getProductionOrderInfoByExternalId2.PATH, 'get');
  if (params) {
    rb.path('externalProductionOrderId', params.externalProductionOrderId, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ProductionOrderExternalInfo>;
    })
  );
}

getProductionOrderInfoByExternalId2.PATH = '/api/v1/ProductionOrder/GetProductionOrderInfoByExternalId/{externalProductionOrderId}';
