/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ProductType {
  Other = 'Other',
  WIP = 'WIP',
  RawMaterial = 'RawMaterial',
  FinishedGood = 'FinishedGood'
}
