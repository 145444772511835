/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SettingHierarchyViewModel } from '../../models/setting-hierarchy-view-model';

export interface GetSettingHierarchy$Params {
  hierarchyType: string;
}

export function getSettingHierarchy(
  http: HttpClient,
  rootUrl: string,
  params: GetSettingHierarchy$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<SettingHierarchyViewModel>> {
  const rb = new RequestBuilder(rootUrl, getSettingHierarchy.PATH, 'get');
  if (params) {
    rb.path('hierarchyType', params.hierarchyType, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SettingHierarchyViewModel>;
    })
  );
}

getSettingHierarchy.PATH = '/api/v1/Settings/GetSettingHierarchy/{hierarchyType}';
