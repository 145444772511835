/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PileTurner } from '../../models/pile-turner';

export interface GetPileTurner$Params {
  pileTurnerWorkCenterId: number;
}

export function getPileTurner(
  http: HttpClient,
  rootUrl: string,
  params: GetPileTurner$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<PileTurner>> {
  const rb = new RequestBuilder(rootUrl, getPileTurner.PATH, 'get');
  if (params) {
    rb.path('pileTurnerWorkCenterId', params.pileTurnerWorkCenterId, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PileTurner>;
    })
  );
}

getPileTurner.PATH = '/api/v1/PileTurner/{pileTurnerWorkCenterId}';
