/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getWorkCenterGroup } from '../fn/work-center-group/get-work-center-group';
import { GetWorkCenterGroup$Params } from '../fn/work-center-group/get-work-center-group';
import { getWorkCenterGroups } from '../fn/work-center-group/get-work-center-groups';
import { GetWorkCenterGroups$Params } from '../fn/work-center-group/get-work-center-groups';
import { getWorkCenterGroupsForActiveMachines } from '../fn/work-center-group/get-work-center-groups-for-active-machines';
import { GetWorkCenterGroupsForActiveMachines$Params } from '../fn/work-center-group/get-work-center-groups-for-active-machines';
import { WorkCenterGroup } from '../models/work-center-group';

@Injectable({ providedIn: 'root' })
export class WorkCenterGroupService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getWorkCenterGroups()` */
  static readonly GetWorkCenterGroupsPath = '/api/v1/WorkCenterGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkCenterGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterGroups$Response(
    params?: GetWorkCenterGroups$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WorkCenterGroup>>> {
    return getWorkCenterGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkCenterGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterGroups(params?: GetWorkCenterGroups$Params, context?: HttpContext): Observable<Array<WorkCenterGroup>> {
    return this.getWorkCenterGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkCenterGroup>>): Array<WorkCenterGroup> => r.body)
    );
  }

  /** Path part for operation `getWorkCenterGroupsForActiveMachines()` */
  static readonly GetWorkCenterGroupsForActiveMachinesPath = '/api/v1/WorkCenterGroup/WorkCenterGroupsForActiveMachines';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkCenterGroupsForActiveMachines()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterGroupsForActiveMachines$Response(
    params?: GetWorkCenterGroupsForActiveMachines$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WorkCenterGroup>>> {
    return getWorkCenterGroupsForActiveMachines(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkCenterGroupsForActiveMachines$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterGroupsForActiveMachines(
    params?: GetWorkCenterGroupsForActiveMachines$Params,
    context?: HttpContext
  ): Observable<Array<WorkCenterGroup>> {
    return this.getWorkCenterGroupsForActiveMachines$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkCenterGroup>>): Array<WorkCenterGroup> => r.body)
    );
  }

  /** Path part for operation `getWorkCenterGroup()` */
  static readonly GetWorkCenterGroupPath = '/api/v1/WorkCenterGroup/{workCenterGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkCenterGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterGroup$Response(params: GetWorkCenterGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkCenterGroup>> {
    return getWorkCenterGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkCenterGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterGroup(params: GetWorkCenterGroup$Params, context?: HttpContext): Observable<WorkCenterGroup> {
    return this.getWorkCenterGroup$Response(params, context).pipe(map((r: StrictHttpResponse<WorkCenterGroup>): WorkCenterGroup => r.body));
  }
}
