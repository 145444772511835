/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UpdateSettingEntry } from '../../models/update-setting-entry';

export interface UpdateSetting$Params {
  settingId: number;
  hierarchyLevel: string;
  externalId: string;
  body: UpdateSettingEntry;
}

export function updateSetting(
  http: HttpClient,
  rootUrl: string,
  params: UpdateSetting$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, updateSetting.PATH, 'post');
  if (params) {
    rb.path('settingId', params.settingId, {});
    rb.path('hierarchyLevel', params.hierarchyLevel, {});
    rb.path('externalId', params.externalId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'text', accept: '*/*', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

updateSetting.PATH = '/api/v1/Settings/{settingId}/{hierarchyLevel}/{externalId}/UpdateSetting';
