/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SuggestQuantityForLastProducedMaterialViewModel } from '../../models/suggest-quantity-for-last-produced-material-view-model';

export interface SuggestQuantityForLastProducedMaterial$Params {
  productionOrderId: number;
}

export function suggestQuantityForLastProducedMaterial(
  http: HttpClient,
  rootUrl: string,
  params: SuggestQuantityForLastProducedMaterial$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<SuggestQuantityForLastProducedMaterialViewModel>> {
  const rb = new RequestBuilder(rootUrl, suggestQuantityForLastProducedMaterial.PATH, 'get');
  if (params) {
    rb.path('productionOrderId', params.productionOrderId, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SuggestQuantityForLastProducedMaterialViewModel>;
    })
  );
}

suggestQuantityForLastProducedMaterial.PATH = '/api/v1/ProductionOrder/{productionOrderId}/SuggestQuantityForLastProducedMaterial';
