/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActiveProductionOrder } from '../models/active-production-order';
import { getActiveOrder } from '../fn/machine-schedule/get-active-order';
import { GetActiveOrder$Params } from '../fn/machine-schedule/get-active-order';
import { getAll } from '../fn/machine-schedule/get-all';
import { GetAll$Params } from '../fn/machine-schedule/get-all';
import { getFiltered } from '../fn/machine-schedule/get-filtered';
import { GetFiltered$Params } from '../fn/machine-schedule/get-filtered';
import { getMachineSchedules } from '../fn/machine-schedule/get-machine-schedules';
import { GetMachineSchedules$Params } from '../fn/machine-schedule/get-machine-schedules';
import { getShiftFilters } from '../fn/machine-schedule/get-shift-filters';
import { GetShiftFilters$Params } from '../fn/machine-schedule/get-shift-filters';
import { MachineSchedule } from '../models/machine-schedule';
import { ShiftFilter } from '../models/shift-filter';

@Injectable({ providedIn: 'root' })
export class MachineScheduleService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMachineSchedules()` */
  static readonly GetMachineSchedulesPath = '/api/v1/MachineSchedule/{workCenterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMachineSchedules()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMachineSchedules$Response(
    params: GetMachineSchedules$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MachineSchedule>>> {
    return getMachineSchedules(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMachineSchedules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMachineSchedules(params: GetMachineSchedules$Params, context?: HttpContext): Observable<Array<MachineSchedule>> {
    return this.getMachineSchedules$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MachineSchedule>>): Array<MachineSchedule> => r.body)
    );
  }

  /** Path part for operation `getAll()` */
  static readonly GetAllPath = '/api/v1/MachineSchedule/{workCenterId}/ShowAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll$Response(params: GetAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MachineSchedule>>> {
    return getAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAll(params: GetAll$Params, context?: HttpContext): Observable<Array<MachineSchedule>> {
    return this.getAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MachineSchedule>>): Array<MachineSchedule> => r.body)
    );
  }

  /** Path part for operation `getFiltered()` */
  static readonly GetFilteredPath = '/api/v1/MachineSchedule/{workCenterId}/Filtered';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFiltered()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFiltered$Response(params: GetFiltered$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MachineSchedule>>> {
    return getFiltered(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFiltered$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFiltered(params: GetFiltered$Params, context?: HttpContext): Observable<Array<MachineSchedule>> {
    return this.getFiltered$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MachineSchedule>>): Array<MachineSchedule> => r.body)
    );
  }

  /** Path part for operation `getActiveOrder()` */
  static readonly GetActiveOrderPath = '/api/v1/MachineSchedule/{workCenterId}/ActiveOrder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveOrder$Response(params: GetActiveOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<ActiveProductionOrder>> {
    return getActiveOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActiveOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveOrder(params: GetActiveOrder$Params, context?: HttpContext): Observable<ActiveProductionOrder> {
    return this.getActiveOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActiveProductionOrder>): ActiveProductionOrder => r.body)
    );
  }

  /** Path part for operation `getShiftFilters()` */
  static readonly GetShiftFiltersPath = '/api/v1/MachineSchedule/{workCenterId}/ShiftFilters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShiftFilters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShiftFilters$Response(params: GetShiftFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShiftFilter>>> {
    return getShiftFilters(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getShiftFilters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShiftFilters(params: GetShiftFilters$Params, context?: HttpContext): Observable<Array<ShiftFilter>> {
    return this.getShiftFilters$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShiftFilter>>): Array<ShiftFilter> => r.body)
    );
  }
}
