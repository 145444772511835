/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EmployeeSignatureViewModel } from '../../models/employee-signature-view-model';

export interface GetEmployeeSignature$Params {
  employeeSignatureId: number;
}

export function getEmployeeSignature(
  http: HttpClient,
  rootUrl: string,
  params: GetEmployeeSignature$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<EmployeeSignatureViewModel>> {
  const rb = new RequestBuilder(rootUrl, getEmployeeSignature.PATH, 'get');
  if (params) {
    rb.path('employeeSignatureId', params.employeeSignatureId, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<EmployeeSignatureViewModel>;
    })
  );
}

getEmployeeSignature.PATH = '/api/v1/GetEmployeeSignature/{employeeSignatureId}';
