/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ProductionOrderBackwardSearch } from '../../models/production-order-backward-search';

export interface GetProductionOrdersForBackwardSearch$Params {
  searchText?: string;
  startDate?: string;
  endDate?: string;
}

export function getProductionOrdersForBackwardSearch(
  http: HttpClient,
  rootUrl: string,
  params?: GetProductionOrdersForBackwardSearch$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<ProductionOrderBackwardSearch>>> {
  const rb = new RequestBuilder(rootUrl, getProductionOrdersForBackwardSearch.PATH, 'get');
  if (params) {
    rb.query('searchText', params.searchText, {});
    rb.query('startDate', params.startDate, {});
    rb.query('endDate', params.endDate, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ProductionOrderBackwardSearch>>;
    })
  );
}

getProductionOrdersForBackwardSearch.PATH = '/api/v1/getProductionOrdersForBackwardSearch';
