/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addWhiteWasteToMountedMaterial } from '../fn/primary-material/add-white-waste-to-mounted-material';
import { AddWhiteWasteToMountedMaterial$Params } from '../fn/primary-material/add-white-waste-to-mounted-material';
import { changeMountedMaterialSequence } from '../fn/primary-material/change-mounted-material-sequence';
import { ChangeMountedMaterialSequence$Params } from '../fn/primary-material/change-mounted-material-sequence';
import { consumePrimaryContainer } from '../fn/primary-material/consume-primary-container';
import { ConsumePrimaryContainer$Params } from '../fn/primary-material/consume-primary-container';
import { consumePrimaryContainer2 } from '../fn/primary-material/consume-primary-container-2';
import { ConsumePrimaryContainer2$Params } from '../fn/primary-material/consume-primary-container-2';
import { createAndMountVirtualPrimaryContainer } from '../fn/primary-material/create-and-mount-virtual-primary-container';
import { CreateAndMountVirtualPrimaryContainer$Params } from '../fn/primary-material/create-and-mount-virtual-primary-container';
import { CreatePrimaryVirtualContainerData } from '../models/create-primary-virtual-container-data';
import { deleteWhiteWasteAssignment } from '../fn/primary-material/delete-white-waste-assignment';
import { DeleteWhiteWasteAssignment$Params } from '../fn/primary-material/delete-white-waste-assignment';
import { dismountContainer } from '../fn/primary-material/dismount-container';
import { DismountContainer$Params } from '../fn/primary-material/dismount-container';
import { getCardViewGroupByMaterial } from '../fn/primary-material/get-card-view-group-by-material';
import { GetCardViewGroupByMaterial$Params } from '../fn/primary-material/get-card-view-group-by-material';
import { getConsumption } from '../fn/primary-material/get-consumption';
import { GetConsumption$Params } from '../fn/primary-material/get-consumption';
import { getListViewGroupByMaterial } from '../fn/primary-material/get-list-view-group-by-material';
import { GetListViewGroupByMaterial$Params } from '../fn/primary-material/get-list-view-group-by-material';
import { getMaterialAvailableAtMachine } from '../fn/primary-material/get-material-available-at-machine';
import { GetMaterialAvailableAtMachine$Params } from '../fn/primary-material/get-material-available-at-machine';
import { getMaterialAvailableAtMachineForProductionOrder } from '../fn/primary-material/get-material-available-at-machine-for-production-order';
import { GetMaterialAvailableAtMachineForProductionOrder$Params } from '../fn/primary-material/get-material-available-at-machine-for-production-order';
import { getMaterialAvailableAtWarehouse } from '../fn/primary-material/get-material-available-at-warehouse';
import { GetMaterialAvailableAtWarehouse$Params } from '../fn/primary-material/get-material-available-at-warehouse';
import { getMaterialAvailableAtWarehouseForProductionOrder } from '../fn/primary-material/get-material-available-at-warehouse-for-production-order';
import { GetMaterialAvailableAtWarehouseForProductionOrder$Params } from '../fn/primary-material/get-material-available-at-warehouse-for-production-order';
import { getMaterialAvailableForProductionOrder } from '../fn/primary-material/get-material-available-for-production-order';
import { GetMaterialAvailableForProductionOrder$Params } from '../fn/primary-material/get-material-available-for-production-order';
import { getMountedMaterials } from '../fn/primary-material/get-mounted-materials';
import { GetMountedMaterials$Params } from '../fn/primary-material/get-mounted-materials';
import { getNextPrimaryContainerForDismount } from '../fn/primary-material/get-next-primary-container-for-dismount';
import { GetNextPrimaryContainerForDismount$Params } from '../fn/primary-material/get-next-primary-container-for-dismount';
import { getPrimaryVirtualContainerCreationData } from '../fn/primary-material/get-primary-virtual-container-creation-data';
import { GetPrimaryVirtualContainerCreationData$Params } from '../fn/primary-material/get-primary-virtual-container-creation-data';
import { MaterialAvailableAtLocation } from '../models/material-available-at-location';
import { mountPrimaryContainer } from '../fn/primary-material/mount-primary-container';
import { MountPrimaryContainer$Params } from '../fn/primary-material/mount-primary-container';
import { MountPrimaryContainerResponse } from '../models/mount-primary-container-response';
import { PrimaryMaterial } from '../models/primary-material';
import { PrimaryMaterialBlock } from '../models/primary-material-block';
import { PrimaryMaterialBlockConsumed } from '../models/primary-material-block-consumed';
import { PrimaryMaterialMounted } from '../models/primary-material-mounted';
import { replaceVirtualPrimaryContainer } from '../fn/primary-material/replace-virtual-primary-container';
import { ReplaceVirtualPrimaryContainer$Params } from '../fn/primary-material/replace-virtual-primary-container';

@Injectable({ providedIn: 'root' })
export class PrimaryMaterialService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `mountPrimaryContainer()` */
  static readonly MountPrimaryContainerPath = '/api/v1/PrimaryMaterial/{workCenterId}/Mount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mountPrimaryContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mountPrimaryContainer$Response(
    params: MountPrimaryContainer$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<MountPrimaryContainerResponse>> {
    return mountPrimaryContainer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mountPrimaryContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mountPrimaryContainer(params: MountPrimaryContainer$Params, context?: HttpContext): Observable<MountPrimaryContainerResponse> {
    return this.mountPrimaryContainer$Response(params, context).pipe(
      map((r: StrictHttpResponse<MountPrimaryContainerResponse>): MountPrimaryContainerResponse => r.body)
    );
  }

  /** Path part for operation `consumePrimaryContainer()` */
  static readonly ConsumePrimaryContainerPath = '/api/v1/PrimaryMaterial/{workCenterId}/Consume';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumePrimaryContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  consumePrimaryContainer$Response(params: ConsumePrimaryContainer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return consumePrimaryContainer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumePrimaryContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  consumePrimaryContainer(params: ConsumePrimaryContainer$Params, context?: HttpContext): Observable<void> {
    return this.consumePrimaryContainer$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `consumePrimaryContainer2()` */
  static readonly ConsumePrimaryContainer2Path = '/api/v1/PrimaryMaterial/{workCenterId}/ConsumeContainers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consumePrimaryContainer2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  consumePrimaryContainer2$Response(params: ConsumePrimaryContainer2$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return consumePrimaryContainer2(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consumePrimaryContainer2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  consumePrimaryContainer2(params: ConsumePrimaryContainer2$Params, context?: HttpContext): Observable<void> {
    return this.consumePrimaryContainer2$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `dismountContainer()` */
  static readonly DismountContainerPath = '/api/v1/PrimaryMaterial/{containerId}/Dismount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dismountContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dismountContainer$Response(params: DismountContainer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return dismountContainer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dismountContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dismountContainer(params: DismountContainer$Params, context?: HttpContext): Observable<void> {
    return this.dismountContainer$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `changeMountedMaterialSequence()` */
  static readonly ChangeMountedMaterialSequencePath = '/api/v1/PrimaryMaterial/{materialBlockId}/ChangeMountedMaterialSequence';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeMountedMaterialSequence()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeMountedMaterialSequence$Response(
    params: ChangeMountedMaterialSequence$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return changeMountedMaterialSequence(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeMountedMaterialSequence$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeMountedMaterialSequence(params: ChangeMountedMaterialSequence$Params, context?: HttpContext): Observable<void> {
    return this.changeMountedMaterialSequence$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `createAndMountVirtualPrimaryContainer()` */
  static readonly CreateAndMountVirtualPrimaryContainerPath =
    '/api/v1/PrimaryMaterial/{workCenterId}/CreateAndMountVirtualPrimaryContainer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAndMountVirtualPrimaryContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAndMountVirtualPrimaryContainer$Response(
    params: CreateAndMountVirtualPrimaryContainer$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return createAndMountVirtualPrimaryContainer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAndMountVirtualPrimaryContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAndMountVirtualPrimaryContainer(params: CreateAndMountVirtualPrimaryContainer$Params, context?: HttpContext): Observable<void> {
    return this.createAndMountVirtualPrimaryContainer$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `replaceVirtualPrimaryContainer()` */
  static readonly ReplaceVirtualPrimaryContainerPath = '/api/v1/PrimaryMaterial/{mountedMaterialId}/ReplaceVirtualContainer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceVirtualPrimaryContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceVirtualPrimaryContainer$Response(
    params: ReplaceVirtualPrimaryContainer$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return replaceVirtualPrimaryContainer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `replaceVirtualPrimaryContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceVirtualPrimaryContainer(params: ReplaceVirtualPrimaryContainer$Params, context?: HttpContext): Observable<void> {
    return this.replaceVirtualPrimaryContainer$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `addWhiteWasteToMountedMaterial()` */
  static readonly AddWhiteWasteToMountedMaterialPath = '/api/v1/PrimaryMaterial/{mountedMaterialId}/AddWhiteWasteToMountedMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addWhiteWasteToMountedMaterial()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addWhiteWasteToMountedMaterial$Response(
    params: AddWhiteWasteToMountedMaterial$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return addWhiteWasteToMountedMaterial(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addWhiteWasteToMountedMaterial$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addWhiteWasteToMountedMaterial(params: AddWhiteWasteToMountedMaterial$Params, context?: HttpContext): Observable<void> {
    return this.addWhiteWasteToMountedMaterial$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `deleteWhiteWasteAssignment()` */
  static readonly DeleteWhiteWasteAssignmentPath = '/api/v1/PrimaryMaterial/{mountedMaterialId}/DeleteWhiteWasteFromMountedMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteWhiteWasteAssignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteWhiteWasteAssignment$Response(
    params: DeleteWhiteWasteAssignment$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return deleteWhiteWasteAssignment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteWhiteWasteAssignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteWhiteWasteAssignment(params: DeleteWhiteWasteAssignment$Params, context?: HttpContext): Observable<void> {
    return this.deleteWhiteWasteAssignment$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getCardViewGroupByMaterial()` */
  static readonly GetCardViewGroupByMaterialPath = '/api/v1/PrimaryMaterial/{workCenterId}/CardView/GroupByMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCardViewGroupByMaterial()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardViewGroupByMaterial$Response(
    params: GetCardViewGroupByMaterial$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<PrimaryMaterial>> {
    return getCardViewGroupByMaterial(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCardViewGroupByMaterial$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCardViewGroupByMaterial(params: GetCardViewGroupByMaterial$Params, context?: HttpContext): Observable<PrimaryMaterial> {
    return this.getCardViewGroupByMaterial$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrimaryMaterial>): PrimaryMaterial => r.body)
    );
  }

  /** Path part for operation `getListViewGroupByMaterial()` */
  static readonly GetListViewGroupByMaterialPath = '/api/v1/PrimaryMaterial/{workCenterId}/ListView/GroupByMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getListViewGroupByMaterial()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListViewGroupByMaterial$Response(
    params: GetListViewGroupByMaterial$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<PrimaryMaterial>> {
    return getListViewGroupByMaterial(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getListViewGroupByMaterial$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListViewGroupByMaterial(params: GetListViewGroupByMaterial$Params, context?: HttpContext): Observable<PrimaryMaterial> {
    return this.getListViewGroupByMaterial$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrimaryMaterial>): PrimaryMaterial => r.body)
    );
  }

  /** Path part for operation `getMountedMaterials()` */
  static readonly GetMountedMaterialsPath = '/api/v1/PrimaryMaterial/{workCenterId}/MountedMaterials';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMountedMaterials()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMountedMaterials$Response(
    params: GetMountedMaterials$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PrimaryMaterialBlock>>> {
    return getMountedMaterials(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMountedMaterials$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMountedMaterials(params: GetMountedMaterials$Params, context?: HttpContext): Observable<Array<PrimaryMaterialBlock>> {
    return this.getMountedMaterials$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PrimaryMaterialBlock>>): Array<PrimaryMaterialBlock> => r.body)
    );
  }

  /** Path part for operation `getConsumption()` */
  static readonly GetConsumptionPath = '/api/v1/PrimaryMaterial/{workCenterId}/Consumption';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsumption()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsumption$Response(
    params: GetConsumption$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<PrimaryMaterialBlockConsumed>> {
    return getConsumption(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsumption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsumption(params: GetConsumption$Params, context?: HttpContext): Observable<PrimaryMaterialBlockConsumed> {
    return this.getConsumption$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrimaryMaterialBlockConsumed>): PrimaryMaterialBlockConsumed => r.body)
    );
  }

  /** Path part for operation `getMaterialAvailableAtMachine()` */
  static readonly GetMaterialAvailableAtMachinePath = '/api/v1/PrimaryMaterial/{workCenterId}/materialAvailableAtMachine';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMaterialAvailableAtMachine()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableAtMachine$Response(
    params: GetMaterialAvailableAtMachine$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<MaterialAvailableAtLocation>> {
    return getMaterialAvailableAtMachine(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMaterialAvailableAtMachine$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableAtMachine(
    params: GetMaterialAvailableAtMachine$Params,
    context?: HttpContext
  ): Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableAtMachine$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaterialAvailableAtLocation>): MaterialAvailableAtLocation => r.body)
    );
  }

  /** Path part for operation `getMaterialAvailableAtMachineForProductionOrder()` */
  static readonly GetMaterialAvailableAtMachineForProductionOrderPath =
    '/api/v1/PrimaryMaterial/{workCenterId}/productionOrders/{productionOrderId}/materialAvailableAtMachine';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMaterialAvailableAtMachineForProductionOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableAtMachineForProductionOrder$Response(
    params: GetMaterialAvailableAtMachineForProductionOrder$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<MaterialAvailableAtLocation>> {
    return getMaterialAvailableAtMachineForProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMaterialAvailableAtMachineForProductionOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableAtMachineForProductionOrder(
    params: GetMaterialAvailableAtMachineForProductionOrder$Params,
    context?: HttpContext
  ): Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableAtMachineForProductionOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaterialAvailableAtLocation>): MaterialAvailableAtLocation => r.body)
    );
  }

  /** Path part for operation `getMaterialAvailableAtWarehouse()` */
  static readonly GetMaterialAvailableAtWarehousePath = '/api/v1/PrimaryMaterial/{workCenterId}/materialAvailableAtWarehouse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMaterialAvailableAtWarehouse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableAtWarehouse$Response(
    params: GetMaterialAvailableAtWarehouse$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<MaterialAvailableAtLocation>> {
    return getMaterialAvailableAtWarehouse(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMaterialAvailableAtWarehouse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableAtWarehouse(
    params: GetMaterialAvailableAtWarehouse$Params,
    context?: HttpContext
  ): Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableAtWarehouse$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaterialAvailableAtLocation>): MaterialAvailableAtLocation => r.body)
    );
  }

  /** Path part for operation `getMaterialAvailableAtWarehouseForProductionOrder()` */
  static readonly GetMaterialAvailableAtWarehouseForProductionOrderPath =
    '/api/v1/PrimaryMaterial/{workCenterId}/productionOrders/{productionOrderId}/materialAvailableAtWarehouse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMaterialAvailableAtWarehouseForProductionOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableAtWarehouseForProductionOrder$Response(
    params: GetMaterialAvailableAtWarehouseForProductionOrder$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<MaterialAvailableAtLocation>> {
    return getMaterialAvailableAtWarehouseForProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMaterialAvailableAtWarehouseForProductionOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableAtWarehouseForProductionOrder(
    params: GetMaterialAvailableAtWarehouseForProductionOrder$Params,
    context?: HttpContext
  ): Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableAtWarehouseForProductionOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaterialAvailableAtLocation>): MaterialAvailableAtLocation => r.body)
    );
  }

  /** Path part for operation `getMaterialAvailableForProductionOrder()` */
  static readonly GetMaterialAvailableForProductionOrderPath =
    '/api/v1/PrimaryMaterial/{workCenterId}/productionOrders/{productionOrderId}/materialAvailable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMaterialAvailableForProductionOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableForProductionOrder$Response(
    params: GetMaterialAvailableForProductionOrder$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<MaterialAvailableAtLocation>> {
    return getMaterialAvailableForProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMaterialAvailableForProductionOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableForProductionOrder(
    params: GetMaterialAvailableForProductionOrder$Params,
    context?: HttpContext
  ): Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableForProductionOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaterialAvailableAtLocation>): MaterialAvailableAtLocation => r.body)
    );
  }

  /** Path part for operation `getNextPrimaryContainerForDismount()` */
  static readonly GetNextPrimaryContainerForDismountPath = '/api/v1/PrimaryMaterial/{workCenterId}/NextPrimaryContainerForDismount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNextPrimaryContainerForDismount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNextPrimaryContainerForDismount$Response(
    params: GetNextPrimaryContainerForDismount$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<PrimaryMaterialMounted>> {
    return getNextPrimaryContainerForDismount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNextPrimaryContainerForDismount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNextPrimaryContainerForDismount(
    params: GetNextPrimaryContainerForDismount$Params,
    context?: HttpContext
  ): Observable<PrimaryMaterialMounted> {
    return this.getNextPrimaryContainerForDismount$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrimaryMaterialMounted>): PrimaryMaterialMounted => r.body)
    );
  }

  /** Path part for operation `getPrimaryVirtualContainerCreationData()` */
  static readonly GetPrimaryVirtualContainerCreationDataPath =
    '/api/v1/PrimaryMaterial/{productionOrderId}/GetPrimaryVirtualContainerCreationData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrimaryVirtualContainerCreationData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrimaryVirtualContainerCreationData$Response(
    params: GetPrimaryVirtualContainerCreationData$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreatePrimaryVirtualContainerData>> {
    return getPrimaryVirtualContainerCreationData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPrimaryVirtualContainerCreationData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrimaryVirtualContainerCreationData(
    params: GetPrimaryVirtualContainerCreationData$Params,
    context?: HttpContext
  ): Observable<CreatePrimaryVirtualContainerData> {
    return this.getPrimaryVirtualContainerCreationData$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreatePrimaryVirtualContainerData>): CreatePrimaryVirtualContainerData => r.body)
    );
  }
}
