/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getProductionOrderPatternInfo } from '../fn/pattern-info/get-production-order-pattern-info';
import { GetProductionOrderPatternInfo$Params } from '../fn/pattern-info/get-production-order-pattern-info';
import { isPatternAvailableForProductionOrder } from '../fn/pattern-info/is-pattern-available-for-production-order';
import { IsPatternAvailableForProductionOrder$Params } from '../fn/pattern-info/is-pattern-available-for-production-order';
import { PatternDetail } from '../models/pattern-detail';

@Injectable({ providedIn: 'root' })
export class PatternInfoService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getProductionOrderPatternInfo()` */
  static readonly GetProductionOrderPatternInfoPath = '/api/v1/PatternInfo/GetProductionOrderPatternInfo/{productionOrderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProductionOrderPatternInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderPatternInfo$Response(
    params: GetProductionOrderPatternInfo$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<PatternDetail>> {
    return getProductionOrderPatternInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProductionOrderPatternInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProductionOrderPatternInfo(params: GetProductionOrderPatternInfo$Params, context?: HttpContext): Observable<PatternDetail> {
    return this.getProductionOrderPatternInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatternDetail>): PatternDetail => r.body)
    );
  }

  /** Path part for operation `isPatternAvailableForProductionOrder()` */
  static readonly IsPatternAvailableForProductionOrderPath = '/api/v1/PatternInfo/IsPatternAvailableForProductionOrder/{productionOrderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isPatternAvailableForProductionOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPatternAvailableForProductionOrder$Response(
    params: IsPatternAvailableForProductionOrder$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    return isPatternAvailableForProductionOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isPatternAvailableForProductionOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isPatternAvailableForProductionOrder(params: IsPatternAvailableForProductionOrder$Params, context?: HttpContext): Observable<boolean> {
    return this.isPatternAvailableForProductionOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }
}
