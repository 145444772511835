/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EmployeeViewModel } from '../../models/employee-view-model';

export interface GetEmployeesForSignature$Params {
  RequireEmployeeRegistration?: boolean;
  WorkCenterId?: number | null;
}

export function getEmployeesForSignature(
  http: HttpClient,
  rootUrl: string,
  params?: GetEmployeesForSignature$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<EmployeeViewModel>> {
  const rb = new RequestBuilder(rootUrl, getEmployeesForSignature.PATH, 'get');
  if (params) {
    rb.query('RequireEmployeeRegistration', params.RequireEmployeeRegistration, {});
    rb.query('WorkCenterId', params.WorkCenterId, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<EmployeeViewModel>;
    })
  );
}

getEmployeesForSignature.PATH = '/api/v1/GetEmployeesForSignature';
