/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { changeProducedMaterialPrintInfo } from '../fn/produced-material/change-produced-material-print-info';
import { ChangeProducedMaterialPrintInfo$Params } from '../fn/produced-material/change-produced-material-print-info';
import { printBobbinCoreLabel } from '../fn/produced-material/print-bobbin-core-label';
import { PrintBobbinCoreLabel$Params } from '../fn/produced-material/print-bobbin-core-label';
import { setForwardedWaste } from '../fn/produced-material/set-forwarded-waste';
import { SetForwardedWaste$Params } from '../fn/produced-material/set-forwarded-waste';

@Injectable({ providedIn: 'root' })
export class ProducedMaterialService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `setForwardedWaste()` */
  static readonly SetForwardedWastePath = '/api/v1/ProducedMaterial/{producedMaterialId}/SetForwardedWaste';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setForwardedWaste()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setForwardedWaste$Response(params: SetForwardedWaste$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setForwardedWaste(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setForwardedWaste$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setForwardedWaste(params: SetForwardedWaste$Params, context?: HttpContext): Observable<void> {
    return this.setForwardedWaste$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `printBobbinCoreLabel()` */
  static readonly PrintBobbinCoreLabelPath = '/api/v1/ProducedMaterial/{producedMaterialId}/PrintBobbinCoreLabel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `printBobbinCoreLabel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  printBobbinCoreLabel$Response(params: PrintBobbinCoreLabel$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return printBobbinCoreLabel(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `printBobbinCoreLabel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  printBobbinCoreLabel(params: PrintBobbinCoreLabel$Params, context?: HttpContext): Observable<void> {
    return this.printBobbinCoreLabel$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `changeProducedMaterialPrintInfo()` */
  static readonly ChangeProducedMaterialPrintInfoPath = '/api/v1/ProducedMaterial/{producedMaterialId}/ChangeProducedMaterialPrintInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeProducedMaterialPrintInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeProducedMaterialPrintInfo$Response(
    params: ChangeProducedMaterialPrintInfo$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return changeProducedMaterialPrintInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeProducedMaterialPrintInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeProducedMaterialPrintInfo(params: ChangeProducedMaterialPrintInfo$Params, context?: HttpContext): Observable<void> {
    return this.changeProducedMaterialPrintInfo$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }
}
