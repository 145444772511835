/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MaterialAvailableAtLocation } from '../../models/material-available-at-location';

export interface GetMaterialAvailableForArticle$Params {
  workCenterId: number;
  articleId: number;
}

export function getMaterialAvailableForArticle(
  http: HttpClient,
  rootUrl: string,
  params: GetMaterialAvailableForArticle$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<MaterialAvailableAtLocation>> {
  const rb = new RequestBuilder(rootUrl, getMaterialAvailableForArticle.PATH, 'get');
  if (params) {
    rb.path('workCenterId', params.workCenterId, {});
    rb.path('articleId', params.articleId, {});
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MaterialAvailableAtLocation>;
    })
  );
}

getMaterialAvailableForArticle.PATH = '/api/v1/SecondaryMaterial/{workCenterId}/materialAvailable/articles/{articleId}';
