/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CheckProductionPeriodEntry } from '../../models/check-production-period-entry';
import { CheckProductionPeriodResponse } from '../../models/check-production-period-response';

export interface CheckProductionPeriod2$Params {
  workCenterId: number;
  body: CheckProductionPeriodEntry;
}

export function checkProductionPeriod2(
  http: HttpClient,
  rootUrl: string,
  params: CheckProductionPeriod2$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<CheckProductionPeriodResponse>> {
  const rb = new RequestBuilder(rootUrl, checkProductionPeriod2.PATH, 'post');
  if (params) {
    rb.path('workCenterId', params.workCenterId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CheckProductionPeriodResponse>;
    })
  );
}

checkProductionPeriod2.PATH = '/api/v1/{workCenterId}/CheckProductionPeriod';
