/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getToolCheckoutInfo } from '../fn/tool/get-tool-checkout-info';
import { GetToolCheckoutInfo$Params } from '../fn/tool/get-tool-checkout-info';
import { setToolCheckoutResult } from '../fn/tool/set-tool-checkout-result';
import { SetToolCheckoutResult$Params } from '../fn/tool/set-tool-checkout-result';
import { ToolCheckoutInfo } from '../models/tool-checkout-info';

@Injectable({ providedIn: 'root' })
export class ToolService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `setToolCheckoutResult()` */
  static readonly SetToolCheckoutResultPath = '/api/v1/Tool/{productionOrderId}/SetToolCheckoutResult';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setToolCheckoutResult()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setToolCheckoutResult$Response(params: SetToolCheckoutResult$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setToolCheckoutResult(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setToolCheckoutResult$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setToolCheckoutResult(params: SetToolCheckoutResult$Params, context?: HttpContext): Observable<void> {
    return this.setToolCheckoutResult$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getToolCheckoutInfo()` */
  static readonly GetToolCheckoutInfoPath = '/api/v1/Tool/{productionOrderId}/GetToolCheckoutInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getToolCheckoutInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getToolCheckoutInfo$Response(
    params: GetToolCheckoutInfo$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ToolCheckoutInfo>>> {
    return getToolCheckoutInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getToolCheckoutInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getToolCheckoutInfo(params: GetToolCheckoutInfo$Params, context?: HttpContext): Observable<Array<ToolCheckoutInfo>> {
    return this.getToolCheckoutInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ToolCheckoutInfo>>): Array<ToolCheckoutInfo> => r.body)
    );
  }
}
