/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EmployeeSignatureViewModel } from '../models/employee-signature-view-model';
import { EmployeeViewModel } from '../models/employee-view-model';
import { getEmployeesForSignature } from '../fn/employee-signature/get-employees-for-signature';
import { GetEmployeesForSignature$Params } from '../fn/employee-signature/get-employees-for-signature';
import { getEmployeeSignature } from '../fn/employee-signature/get-employee-signature';
import { GetEmployeeSignature$Params } from '../fn/employee-signature/get-employee-signature';
import { resetSignature } from '../fn/employee-signature/reset-signature';
import { ResetSignature$Params } from '../fn/employee-signature/reset-signature';
import { scanSignature } from '../fn/employee-signature/scan-signature';
import { ScanSignature$Params } from '../fn/employee-signature/scan-signature';
import { ScanSignatureResponse } from '../models/scan-signature-response';
import { setSignatureForQaChecks } from '../fn/employee-signature/set-signature-for-qa-checks';
import { SetSignatureForQaChecks$Params } from '../fn/employee-signature/set-signature-for-qa-checks';

@Injectable({ providedIn: 'root' })
export class EmployeeSignatureService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `scanSignature()` */
  static readonly ScanSignaturePath = '/api/v1/ScanSignature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanSignature()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scanSignature$Response(params: ScanSignature$Params, context?: HttpContext): Observable<StrictHttpResponse<ScanSignatureResponse>> {
    return scanSignature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanSignature$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scanSignature(params: ScanSignature$Params, context?: HttpContext): Observable<ScanSignatureResponse> {
    return this.scanSignature$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScanSignatureResponse>): ScanSignatureResponse => r.body)
    );
  }

  /** Path part for operation `setSignatureForQaChecks()` */
  static readonly SetSignatureForQaChecksPath = '/api/v1/SetSignatureForQaChecks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setSignatureForQaChecks()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSignatureForQaChecks$Response(params: SetSignatureForQaChecks$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setSignatureForQaChecks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setSignatureForQaChecks$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setSignatureForQaChecks(params: SetSignatureForQaChecks$Params, context?: HttpContext): Observable<void> {
    return this.setSignatureForQaChecks$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `resetSignature()` */
  static readonly ResetSignaturePath = '/api/v1/ResetSignature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetSignature()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetSignature$Response(params: ResetSignature$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resetSignature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetSignature$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetSignature(params: ResetSignature$Params, context?: HttpContext): Observable<void> {
    return this.resetSignature$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getEmployeeSignature()` */
  static readonly GetEmployeeSignaturePath = '/api/v1/GetEmployeeSignature/{employeeSignatureId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeSignature()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeSignature$Response(
    params: GetEmployeeSignature$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<EmployeeSignatureViewModel>> {
    return getEmployeeSignature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEmployeeSignature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeSignature(params: GetEmployeeSignature$Params, context?: HttpContext): Observable<EmployeeSignatureViewModel> {
    return this.getEmployeeSignature$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmployeeSignatureViewModel>): EmployeeSignatureViewModel => r.body)
    );
  }

  /** Path part for operation `getEmployeesForSignature()` */
  static readonly GetEmployeesForSignaturePath = '/api/v1/GetEmployeesForSignature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeesForSignature()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeesForSignature$Response(
    params?: GetEmployeesForSignature$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<EmployeeViewModel>> {
    return getEmployeesForSignature(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEmployeesForSignature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeesForSignature(params?: GetEmployeesForSignature$Params, context?: HttpContext): Observable<EmployeeViewModel> {
    return this.getEmployeesForSignature$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmployeeViewModel>): EmployeeViewModel => r.body)
    );
  }
}
