/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AvailableKpisResult } from '../models/available-kpis-result';
import { CounterInfo } from '../models/counter-info';
import { getAvailableKpiIdentifiers } from '../fn/work-center/get-available-kpi-identifiers';
import { GetAvailableKpiIdentifiers$Params } from '../fn/work-center/get-available-kpi-identifiers';
import { getCurrentState } from '../fn/work-center/get-current-state';
import { GetCurrentState$Params } from '../fn/work-center/get-current-state';
import { getKpis } from '../fn/work-center/get-kpis';
import { GetKpis$Params } from '../fn/work-center/get-kpis';
import { getOpenPeriods } from '../fn/work-center/get-open-periods';
import { GetOpenPeriods$Params } from '../fn/work-center/get-open-periods';
import { getPeriods } from '../fn/work-center/get-periods';
import { GetPeriods$Params } from '../fn/work-center/get-periods';
import { getQaHistoryParameterValue } from '../fn/work-center/get-qa-history-parameter-value';
import { GetQaHistoryParameterValue$Params } from '../fn/work-center/get-qa-history-parameter-value';
import { getQuantityPool } from '../fn/work-center/get-quantity-pool';
import { GetQuantityPool$Params } from '../fn/work-center/get-quantity-pool';
import { getShutdownInfo } from '../fn/work-center/get-shutdown-info';
import { GetShutdownInfo$Params } from '../fn/work-center/get-shutdown-info';
import { getShutdownReasons } from '../fn/work-center/get-shutdown-reasons';
import { GetShutdownReasons$Params } from '../fn/work-center/get-shutdown-reasons';
import { getWasteAssignments } from '../fn/work-center/get-waste-assignments';
import { GetWasteAssignments$Params } from '../fn/work-center/get-waste-assignments';
import { getWasteReasons } from '../fn/work-center/get-waste-reasons';
import { GetWasteReasons$Params } from '../fn/work-center/get-waste-reasons';
import { getWorkCenter } from '../fn/work-center/get-work-center';
import { GetWorkCenter$Params } from '../fn/work-center/get-work-center';
import { getWorkCenterByExternalId } from '../fn/work-center/get-work-center-by-external-id';
import { GetWorkCenterByExternalId$Params } from '../fn/work-center/get-work-center-by-external-id';
import { getWorkCenterDetail } from '../fn/work-center/get-work-center-detail';
import { GetWorkCenterDetail$Params } from '../fn/work-center/get-work-center-detail';
import { getWorkCenterInfoByExternalId } from '../fn/work-center/get-work-center-info-by-external-id';
import { GetWorkCenterInfoByExternalId$Params } from '../fn/work-center/get-work-center-info-by-external-id';
import { getWorkCenterList } from '../fn/work-center/get-work-center-list';
import { GetWorkCenterList$Params } from '../fn/work-center/get-work-center-list';
import { getWorkCenterMachineDataList } from '../fn/work-center/get-work-center-machine-data-list';
import { GetWorkCenterMachineDataList$Params } from '../fn/work-center/get-work-center-machine-data-list';
import { getWorkCenters } from '../fn/work-center/get-work-centers';
import { GetWorkCenters$Params } from '../fn/work-center/get-work-centers';
import { KpiValue } from '../models/kpi-value';
import { ProductionPeriod } from '../models/production-period';
import { ProductionPeriodDetailData } from '../models/production-period-detail-data';
import { QuantityPool } from '../models/quantity-pool';
import { scanContainer } from '../fn/work-center/scan-container';
import { ScanContainer$Params } from '../fn/work-center/scan-container';
import { ScanContainerResponse } from '../models/scan-container-response';
import { shutdown } from '../fn/work-center/shutdown';
import { Shutdown$Params } from '../fn/work-center/shutdown';
import { ShutdownInfo } from '../models/shutdown-info';
import { ShutdownReason } from '../models/shutdown-reason';
import { startup } from '../fn/work-center/startup';
import { Startup$Params } from '../fn/work-center/startup';
import { WasteAssignmentInTime } from '../models/waste-assignment-in-time';
import { WasteReason } from '../models/waste-reason';
import { WorkCenter } from '../models/work-center';
import { WorkCenterData } from '../models/work-center-data';
import { WorkCenterDetail } from '../models/work-center-detail';
import { WorkCenterExternalInfo } from '../models/work-center-external-info';
import { workCenterGetCurrentCounter } from '../fn/work-center/work-center-get-current-counter';
import { WorkCenterGetCurrentCounter$Params } from '../fn/work-center/work-center-get-current-counter';
import { WorkCenterMachineData } from '../models/work-center-machine-data';
import { WorkCenterQaHistoryParameter } from '../models/work-center-qa-history-parameter';
import { WorkCenterState } from '../models/work-center-state';

@Injectable({ providedIn: 'root' })
export class WorkCenterService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `shutdown()` */
  static readonly ShutdownPath = '/api/v1/WorkCenter/{workCenterId}/Shutdown';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shutdown()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shutdown$Response(params: Shutdown$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return shutdown(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shutdown$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shutdown(params: Shutdown$Params, context?: HttpContext): Observable<void> {
    return this.shutdown$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `startup()` */
  static readonly StartupPath = '/api/v1/WorkCenter/{workCenterId}/Startup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startup()` instead.
   *
   * This method doesn't expect any request body.
   */
  startup$Response(params: Startup$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return startup(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startup(params: Startup$Params, context?: HttpContext): Observable<void> {
    return this.startup$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `scanContainer()` */
  static readonly ScanContainerPath = '/api/v1/WorkCenter/{workCenterId}/Scan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scanContainer$Response(params: ScanContainer$Params, context?: HttpContext): Observable<StrictHttpResponse<ScanContainerResponse>> {
    return scanContainer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scanContainer(params: ScanContainer$Params, context?: HttpContext): Observable<ScanContainerResponse> {
    return this.scanContainer$Response(params, context).pipe(
      map((r: StrictHttpResponse<ScanContainerResponse>): ScanContainerResponse => r.body)
    );
  }

  /** Path part for operation `getCurrentState()` */
  static readonly GetCurrentStatePath = '/api/v1/WorkCenter/{workCenterId}/CurrentState';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentState()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentState$Response(params: GetCurrentState$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkCenterState>> {
    return getCurrentState(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentState$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentState(params: GetCurrentState$Params, context?: HttpContext): Observable<WorkCenterState> {
    return this.getCurrentState$Response(params, context).pipe(map((r: StrictHttpResponse<WorkCenterState>): WorkCenterState => r.body));
  }

  /** Path part for operation `getWorkCenter()` */
  static readonly GetWorkCenterPath = '/api/v1/WorkCenter/{workCenterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkCenter()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenter$Response(params: GetWorkCenter$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkCenter>> {
    return getWorkCenter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkCenter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenter(params: GetWorkCenter$Params, context?: HttpContext): Observable<WorkCenter> {
    return this.getWorkCenter$Response(params, context).pipe(map((r: StrictHttpResponse<WorkCenter>): WorkCenter => r.body));
  }

  /** Path part for operation `getWorkCenterList()` */
  static readonly GetWorkCenterListPath = '/api/v1/WorkCenter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkCenterList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterList$Response(
    params?: GetWorkCenterList$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WorkCenterData>>> {
    return getWorkCenterList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkCenterList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterList(params?: GetWorkCenterList$Params, context?: HttpContext): Observable<Array<WorkCenterData>> {
    return this.getWorkCenterList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkCenterData>>): Array<WorkCenterData> => r.body)
    );
  }

  /** Path part for operation `getWorkCenters()` */
  static readonly GetWorkCentersPath = '/api/v1/WorkCenter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkCenters()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWorkCenters$Response(params: GetWorkCenters$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WorkCenter>>> {
    return getWorkCenters(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkCenters$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWorkCenters(params: GetWorkCenters$Params, context?: HttpContext): Observable<Array<WorkCenter>> {
    return this.getWorkCenters$Response(params, context).pipe(map((r: StrictHttpResponse<Array<WorkCenter>>): Array<WorkCenter> => r.body));
  }

  /** Path part for operation `getWorkCenterDetail()` */
  static readonly GetWorkCenterDetailPath = '/api/v1/WorkCenter/{workCenterId}/detail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkCenterDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterDetail$Response(
    params: GetWorkCenterDetail$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<WorkCenterDetail>> {
    return getWorkCenterDetail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkCenterDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterDetail(params: GetWorkCenterDetail$Params, context?: HttpContext): Observable<WorkCenterDetail> {
    return this.getWorkCenterDetail$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkCenterDetail>): WorkCenterDetail => r.body)
    );
  }

  /** Path part for operation `getWorkCenterByExternalId()` */
  static readonly GetWorkCenterByExternalIdPath = '/api/v1/WorkCenter/GetWorkCenterByExternalId/{externalWorkCenterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkCenterByExternalId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterByExternalId$Response(
    params: GetWorkCenterByExternalId$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<WorkCenter>> {
    return getWorkCenterByExternalId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkCenterByExternalId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterByExternalId(params: GetWorkCenterByExternalId$Params, context?: HttpContext): Observable<WorkCenter> {
    return this.getWorkCenterByExternalId$Response(params, context).pipe(map((r: StrictHttpResponse<WorkCenter>): WorkCenter => r.body));
  }

  /** Path part for operation `getWorkCenterInfoByExternalId()` */
  static readonly GetWorkCenterInfoByExternalIdPath = '/api/v1/WorkCenter/GetWorkCenterInfoByExternalId/{externalWorkCenterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkCenterInfoByExternalId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterInfoByExternalId$Response(
    params: GetWorkCenterInfoByExternalId$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<WorkCenterExternalInfo>> {
    return getWorkCenterInfoByExternalId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkCenterInfoByExternalId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterInfoByExternalId(params: GetWorkCenterInfoByExternalId$Params, context?: HttpContext): Observable<WorkCenterExternalInfo> {
    return this.getWorkCenterInfoByExternalId$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkCenterExternalInfo>): WorkCenterExternalInfo => r.body)
    );
  }

  /** Path part for operation `getWorkCenterMachineDataList()` */
  static readonly GetWorkCenterMachineDataListPath = '/api/v1/WorkCenter/WorkCenterMachineDataList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkCenterMachineDataList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterMachineDataList$Response(
    params?: GetWorkCenterMachineDataList$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WorkCenterMachineData>>> {
    return getWorkCenterMachineDataList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkCenterMachineDataList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkCenterMachineDataList(
    params?: GetWorkCenterMachineDataList$Params,
    context?: HttpContext
  ): Observable<Array<WorkCenterMachineData>> {
    return this.getWorkCenterMachineDataList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkCenterMachineData>>): Array<WorkCenterMachineData> => r.body)
    );
  }

  /** Path part for operation `getShutdownReasons()` */
  static readonly GetShutdownReasonsPath = '/api/v1/WorkCenter/ShutdownReasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShutdownReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShutdownReasons$Response(
    params?: GetShutdownReasons$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ShutdownReason>>> {
    return getShutdownReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getShutdownReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShutdownReasons(params?: GetShutdownReasons$Params, context?: HttpContext): Observable<Array<ShutdownReason>> {
    return this.getShutdownReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShutdownReason>>): Array<ShutdownReason> => r.body)
    );
  }

  /** Path part for operation `getOpenPeriods()` */
  static readonly GetOpenPeriodsPath = '/api/v1/WorkCenter/{workCenterId}/GetOpenPeriods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpenPeriods()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenPeriods$Response(params: GetOpenPeriods$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductionPeriod>>> {
    return getOpenPeriods(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOpenPeriods$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpenPeriods(params: GetOpenPeriods$Params, context?: HttpContext): Observable<Array<ProductionPeriod>> {
    return this.getOpenPeriods$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductionPeriod>>): Array<ProductionPeriod> => r.body)
    );
  }

  /** Path part for operation `getPeriods()` */
  static readonly GetPeriodsPath = '/api/v1/WorkCenter/{workCenterId}/GetPeriods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPeriods()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPeriods$Response(params: GetPeriods$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductionPeriodDetailData>>> {
    return getPeriods(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPeriods$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPeriods(params: GetPeriods$Params, context?: HttpContext): Observable<Array<ProductionPeriodDetailData>> {
    return this.getPeriods$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductionPeriodDetailData>>): Array<ProductionPeriodDetailData> => r.body)
    );
  }

  /** Path part for operation `getWasteReasons()` */
  static readonly GetWasteReasonsPath = '/api/v1/WorkCenter/{workCenterId}/wasteReasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWasteReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWasteReasons$Response(params: GetWasteReasons$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WasteReason>>> {
    return getWasteReasons(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWasteReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWasteReasons(params: GetWasteReasons$Params, context?: HttpContext): Observable<Array<WasteReason>> {
    return this.getWasteReasons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WasteReason>>): Array<WasteReason> => r.body)
    );
  }

  /** Path part for operation `getShutdownInfo()` */
  static readonly GetShutdownInfoPath = '/api/v1/WorkCenter/{workCenterId}/ShutdownInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShutdownInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShutdownInfo$Response(params: GetShutdownInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<ShutdownInfo>> {
    return getShutdownInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getShutdownInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShutdownInfo(params: GetShutdownInfo$Params, context?: HttpContext): Observable<ShutdownInfo> {
    return this.getShutdownInfo$Response(params, context).pipe(map((r: StrictHttpResponse<ShutdownInfo>): ShutdownInfo => r.body));
  }

  /** Path part for operation `workCenterGetCurrentCounter()` */
  static readonly WorkCenterGetCurrentCounterPath = '/api/v1/WorkCenter/{workCenterId}/CurrentCounter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workCenterGetCurrentCounter()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  workCenterGetCurrentCounter$Response(
    params: WorkCenterGetCurrentCounter$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<CounterInfo>> {
    return workCenterGetCurrentCounter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workCenterGetCurrentCounter$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  workCenterGetCurrentCounter(params: WorkCenterGetCurrentCounter$Params, context?: HttpContext): Observable<CounterInfo> {
    return this.workCenterGetCurrentCounter$Response(params, context).pipe(
      map((r: StrictHttpResponse<CounterInfo>): CounterInfo => r.body)
    );
  }

  /** Path part for operation `getQuantityPool()` */
  static readonly GetQuantityPoolPath = '/api/v1/WorkCenter/{workCenterId}/QuantityPool';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuantityPool()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuantityPool$Response(params: GetQuantityPool$Params, context?: HttpContext): Observable<StrictHttpResponse<QuantityPool>> {
    return getQuantityPool(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuantityPool$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuantityPool(params: GetQuantityPool$Params, context?: HttpContext): Observable<QuantityPool> {
    return this.getQuantityPool$Response(params, context).pipe(map((r: StrictHttpResponse<QuantityPool>): QuantityPool => r.body));
  }

  /** Path part for operation `getQaHistoryParameterValue()` */
  static readonly GetQaHistoryParameterValuePath = '/api/v1/WorkCenter/{workCenterId}/QaHistoryParameterValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQaHistoryParameterValue()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQaHistoryParameterValue$Response(
    params: GetQaHistoryParameterValue$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<WorkCenterQaHistoryParameter>> {
    return getQaHistoryParameterValue(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQaHistoryParameterValue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQaHistoryParameterValue(params: GetQaHistoryParameterValue$Params, context?: HttpContext): Observable<WorkCenterQaHistoryParameter> {
    return this.getQaHistoryParameterValue$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkCenterQaHistoryParameter>): WorkCenterQaHistoryParameter => r.body)
    );
  }

  /** Path part for operation `getAvailableKpiIdentifiers()` */
  static readonly GetAvailableKpiIdentifiersPath = '/api/v1/WorkCenter/{workCenterId}/AvailableKpis';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableKpiIdentifiers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableKpiIdentifiers$Response(
    params: GetAvailableKpiIdentifiers$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<AvailableKpisResult>> {
    return getAvailableKpiIdentifiers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailableKpiIdentifiers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableKpiIdentifiers(params: GetAvailableKpiIdentifiers$Params, context?: HttpContext): Observable<AvailableKpisResult> {
    return this.getAvailableKpiIdentifiers$Response(params, context).pipe(
      map((r: StrictHttpResponse<AvailableKpisResult>): AvailableKpisResult => r.body)
    );
  }

  /** Path part for operation `getKpis()` */
  static readonly GetKpisPath = '/api/v1/WorkCenter/{workCenterId}/Kpis';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKpis()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getKpis$Response(params: GetKpis$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KpiValue>>> {
    return getKpis(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getKpis$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getKpis(params: GetKpis$Params, context?: HttpContext): Observable<Array<KpiValue>> {
    return this.getKpis$Response(params, context).pipe(map((r: StrictHttpResponse<Array<KpiValue>>): Array<KpiValue> => r.body));
  }

  /** Path part for operation `getWasteAssignments()` */
  static readonly GetWasteAssignmentsPath = '/api/v1/WorkCenter/{workCenterId}/wasteAssignments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWasteAssignments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWasteAssignments$Response(
    params: GetWasteAssignments$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<WasteAssignmentInTime>>> {
    return getWasteAssignments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWasteAssignments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWasteAssignments(params: GetWasteAssignments$Params, context?: HttpContext): Observable<Array<WasteAssignmentInTime>> {
    return this.getWasteAssignments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WasteAssignmentInTime>>): Array<WasteAssignmentInTime> => r.body)
    );
  }
}
