/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { exportSettings } from '../fn/settings/export-settings';
import { ExportSettings$Params } from '../fn/settings/export-settings';
import { ExportSettingsResponse } from '../models/export-settings-response';
import { getSettingEntityInfo } from '../fn/settings/get-setting-entity-info';
import { GetSettingEntityInfo$Params } from '../fn/settings/get-setting-entity-info';
import { getSettingHierarchy } from '../fn/settings/get-setting-hierarchy';
import { GetSettingHierarchy$Params } from '../fn/settings/get-setting-hierarchy';
import { getSettingHistory } from '../fn/settings/get-setting-history';
import { GetSettingHistory$Params } from '../fn/settings/get-setting-history';
import { getSettings } from '../fn/settings/get-settings';
import { GetSettings$Params } from '../fn/settings/get-settings';
import { getSettingValues } from '../fn/settings/get-setting-values';
import { GetSettingValues$Params } from '../fn/settings/get-setting-values';
import { importSettings } from '../fn/settings/import-settings';
import { ImportSettings$Params } from '../fn/settings/import-settings';
import { refreshSettings } from '../fn/settings/refresh-settings';
import { RefreshSettings$Params } from '../fn/settings/refresh-settings';
import { Setting } from '../models/setting';
import { SettingEntityInfoViewModel } from '../models/setting-entity-info-view-model';
import { SettingHierarchyViewModel } from '../models/setting-hierarchy-view-model';
import { SettingHistoryViewModel } from '../models/setting-history-view-model';
import { SettingValuesViewModel } from '../models/setting-values-view-model';
import { updateSetting } from '../fn/settings/update-setting';
import { UpdateSetting$Params } from '../fn/settings/update-setting';
import { updateSettingsBatch } from '../fn/settings/update-settings-batch';
import { UpdateSettingsBatch$Params } from '../fn/settings/update-settings-batch';

@Injectable({ providedIn: 'root' })
export class SettingsService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `refreshSettings()` */
  static readonly RefreshSettingsPath = '/api/v1/Settings/{externalWorkCenterId}/RefreshSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refreshSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshSettings$Response(params: RefreshSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return refreshSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `refreshSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshSettings(params: RefreshSettings$Params, context?: HttpContext): Observable<void> {
    return this.refreshSettings$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `updateSetting()` */
  static readonly UpdateSettingPath = '/api/v1/Settings/{settingId}/{hierarchyLevel}/{externalId}/UpdateSetting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSetting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSetting$Response(params: UpdateSetting$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateSetting(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSetting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSetting(params: UpdateSetting$Params, context?: HttpContext): Observable<void> {
    return this.updateSetting$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `updateSettingsBatch()` */
  static readonly UpdateSettingsBatchPath = '/api/v1/Settings/{hierarchyLevel}/{externalId}/UpdateSettingsBatch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSettingsBatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSettingsBatch$Response(params: UpdateSettingsBatch$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateSettingsBatch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSettingsBatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSettingsBatch(params: UpdateSettingsBatch$Params, context?: HttpContext): Observable<void> {
    return this.updateSettingsBatch$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `exportSettings()` */
  static readonly ExportSettingsPath = '/api/v1/Settings/ExportSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportSettings$Response(params?: ExportSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<ExportSettingsResponse>> {
    return exportSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportSettings(params?: ExportSettings$Params, context?: HttpContext): Observable<ExportSettingsResponse> {
    return this.exportSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportSettingsResponse>): ExportSettingsResponse => r.body)
    );
  }

  /** Path part for operation `importSettings()` */
  static readonly ImportSettingsPath = '/api/v1/Settings/ImportSettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importSettings$Response(params: ImportSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return importSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  importSettings(params: ImportSettings$Params, context?: HttpContext): Observable<void> {
    return this.importSettings$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getSettings()` */
  static readonly GetSettingsPath = '/api/v1/Settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettings$Response(params?: GetSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Setting>>> {
    return getSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettings(params?: GetSettings$Params, context?: HttpContext): Observable<Array<Setting>> {
    return this.getSettings$Response(params, context).pipe(map((r: StrictHttpResponse<Array<Setting>>): Array<Setting> => r.body));
  }

  /** Path part for operation `getSettingValues()` */
  static readonly GetSettingValuesPath = '/api/v1/Settings/GetSettingValues/{hierarchyType}/{hierarchyLevel}/{entityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingValues()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingValues$Response(
    params: GetSettingValues$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SettingValuesViewModel>>> {
    return getSettingValues(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSettingValues$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingValues(params: GetSettingValues$Params, context?: HttpContext): Observable<Array<SettingValuesViewModel>> {
    return this.getSettingValues$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SettingValuesViewModel>>): Array<SettingValuesViewModel> => r.body)
    );
  }

  /** Path part for operation `getSettingHierarchy()` */
  static readonly GetSettingHierarchyPath = '/api/v1/Settings/GetSettingHierarchy/{hierarchyType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingHierarchy()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingHierarchy$Response(
    params: GetSettingHierarchy$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<SettingHierarchyViewModel>> {
    return getSettingHierarchy(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSettingHierarchy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingHierarchy(params: GetSettingHierarchy$Params, context?: HttpContext): Observable<SettingHierarchyViewModel> {
    return this.getSettingHierarchy$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingHierarchyViewModel>): SettingHierarchyViewModel => r.body)
    );
  }

  /** Path part for operation `getSettingHistory()` */
  static readonly GetSettingHistoryPath = '/api/v1/Settings/GetSettingHistory/{settingId}/{hierarchyType}/{hierarchyLevel}/{entityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingHistory$Response(
    params: GetSettingHistory$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SettingHistoryViewModel>>> {
    return getSettingHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSettingHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingHistory(params: GetSettingHistory$Params, context?: HttpContext): Observable<Array<SettingHistoryViewModel>> {
    return this.getSettingHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SettingHistoryViewModel>>): Array<SettingHistoryViewModel> => r.body)
    );
  }

  /** Path part for operation `getSettingEntityInfo()` */
  static readonly GetSettingEntityInfoPath = '/api/v1/Settings/GetSettingEntityInfo/{hierarchyType}/{hierarchyLevel}/{entityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingEntityInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingEntityInfo$Response(
    params: GetSettingEntityInfo$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<SettingEntityInfoViewModel>> {
    return getSettingEntityInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSettingEntityInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingEntityInfo(params: GetSettingEntityInfo$Params, context?: HttpContext): Observable<SettingEntityInfoViewModel> {
    return this.getSettingEntityInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingEntityInfoViewModel>): SettingEntityInfoViewModel => r.body)
    );
  }
}
