/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AllProductionQaChecks } from '../models/all-production-qa-checks';
import { AllProductionQaChecksFromPreviousOrder } from '../models/all-production-qa-checks-from-previous-order';
import { createManualQaChecks } from '../fn/qa-check/create-manual-qa-checks';
import { CreateManualQaChecks$Params } from '../fn/qa-check/create-manual-qa-checks';
import { createSamplingDataForProducedMaterial } from '../fn/qa-check/create-sampling-data-for-produced-material';
import { CreateSamplingDataForProducedMaterial$Params } from '../fn/qa-check/create-sampling-data-for-produced-material';
import { createSamplingDataForRun } from '../fn/qa-check/create-sampling-data-for-run';
import { CreateSamplingDataForRun$Params } from '../fn/qa-check/create-sampling-data-for-run';
import { getActivePeriodicCheck } from '../fn/qa-check/get-active-periodic-check';
import { GetActivePeriodicCheck$Params } from '../fn/qa-check/get-active-periodic-check';
import { getAllQaChecks } from '../fn/qa-check/get-all-qa-checks';
import { GetAllQaChecks$Params } from '../fn/qa-check/get-all-qa-checks';
import { getAllQaChecksFromPreviousOrders } from '../fn/qa-check/get-all-qa-checks-from-previous-orders';
import { GetAllQaChecksFromPreviousOrders$Params } from '../fn/qa-check/get-all-qa-checks-from-previous-orders';
import { getContainerProductionQaChecks } from '../fn/qa-check/get-container-production-qa-checks';
import { GetContainerProductionQaChecks$Params } from '../fn/qa-check/get-container-production-qa-checks';
import { getLineClearanceQaChecks } from '../fn/qa-check/get-line-clearance-qa-checks';
import { GetLineClearanceQaChecks$Params } from '../fn/qa-check/get-line-clearance-qa-checks';
import { getManualProductionQaChecks } from '../fn/qa-check/get-manual-production-qa-checks';
import { GetManualProductionQaChecks$Params } from '../fn/qa-check/get-manual-production-qa-checks';
import { getQaCheckerHeader } from '../fn/qa-check/get-qa-checker-header';
import { GetQaCheckerHeader$Params } from '../fn/qa-check/get-qa-checker-header';
import { getQaChecksForContainer } from '../fn/qa-check/get-qa-checks-for-container';
import { GetQaChecksForContainer$Params } from '../fn/qa-check/get-qa-checks-for-container';
import { getSamplingData } from '../fn/qa-check/get-sampling-data';
import { GetSamplingData$Params } from '../fn/qa-check/get-sampling-data';
import { getSetupProductionQaChecks } from '../fn/qa-check/get-setup-production-qa-checks';
import { GetSetupProductionQaChecks$Params } from '../fn/qa-check/get-setup-production-qa-checks';
import { getSetupProductionQaChecksAll } from '../fn/qa-check/get-setup-production-qa-checks-all';
import { GetSetupProductionQaChecksAll$Params } from '../fn/qa-check/get-setup-production-qa-checks-all';
import { PeriodicQaCheck } from '../models/periodic-qa-check';
import { ProductionQaCheck } from '../models/production-qa-check';
import { QaCheck } from '../models/qa-check';
import { QaCheckHeaderInfo } from '../models/qa-check-header-info';
import { SamplingDataListViewModel } from '../models/sampling-data-list-view-model';
import { setAllQaChecksForPeriodic } from '../fn/qa-check/set-all-qa-checks-for-periodic';
import { SetAllQaChecksForPeriodic$Params } from '../fn/qa-check/set-all-qa-checks-for-periodic';
import { setAllQaChecksForProducedMaterial } from '../fn/qa-check/set-all-qa-checks-for-produced-material';
import { SetAllQaChecksForProducedMaterial$Params } from '../fn/qa-check/set-all-qa-checks-for-produced-material';
import { setAllQaChecksForSetup } from '../fn/qa-check/set-all-qa-checks-for-setup';
import { SetAllQaChecksForSetup$Params } from '../fn/qa-check/set-all-qa-checks-for-setup';
import { setCheckResult } from '../fn/qa-check/set-check-result';
import { SetCheckResult$Params } from '../fn/qa-check/set-check-result';
import { setQaInfo } from '../fn/qa-check/set-qa-info';
import { SetQaInfo$Params } from '../fn/qa-check/set-qa-info';

@Injectable({ providedIn: 'root' })
export class QaCheckService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `setCheckResult()` */
  static readonly SetCheckResultPath = '/api/v1/QaCheck/{workCenterId}/SetCheckResult/{productionQaCheckId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCheckResult()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setCheckResult$Response(params: SetCheckResult$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setCheckResult(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setCheckResult$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setCheckResult(params: SetCheckResult$Params, context?: HttpContext): Observable<void> {
    return this.setCheckResult$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `setAllQaChecksForSetup()` */
  static readonly SetAllQaChecksForSetupPath = '/api/v1/QaCheck/{workCenterId}/SetAllQaChecksForSetup/{productionOrderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setAllQaChecksForSetup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAllQaChecksForSetup$Response(params: SetAllQaChecksForSetup$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setAllQaChecksForSetup(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setAllQaChecksForSetup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAllQaChecksForSetup(params: SetAllQaChecksForSetup$Params, context?: HttpContext): Observable<void> {
    return this.setAllQaChecksForSetup$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `setAllQaChecksForProducedMaterial()` */
  static readonly SetAllQaChecksForProducedMaterialPath =
    '/api/v1/QaCheck/{workCenterId}/SetAllQaChecksForProducedMaterial/{producedMaterialId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setAllQaChecksForProducedMaterial()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAllQaChecksForProducedMaterial$Response(
    params: SetAllQaChecksForProducedMaterial$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return setAllQaChecksForProducedMaterial(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setAllQaChecksForProducedMaterial$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAllQaChecksForProducedMaterial(params: SetAllQaChecksForProducedMaterial$Params, context?: HttpContext): Observable<void> {
    return this.setAllQaChecksForProducedMaterial$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `setAllQaChecksForPeriodic()` */
  static readonly SetAllQaChecksForPeriodicPath = '/api/v1/QaCheck/{workCenterId}/SetAllQaChecksForPeriodic/{periodicQaCheckId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setAllQaChecksForPeriodic()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAllQaChecksForPeriodic$Response(
    params: SetAllQaChecksForPeriodic$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return setAllQaChecksForPeriodic(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setAllQaChecksForPeriodic$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAllQaChecksForPeriodic(params: SetAllQaChecksForPeriodic$Params, context?: HttpContext): Observable<void> {
    return this.setAllQaChecksForPeriodic$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `createManualQaChecks()` */
  static readonly CreateManualQaChecksPath = '/api/v1/QaCheck/{workCenterId}/CreateManualQaChecks/{productionOrderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createManualQaChecks()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createManualQaChecks$Response(params: CreateManualQaChecks$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createManualQaChecks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createManualQaChecks$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createManualQaChecks(params: CreateManualQaChecks$Params, context?: HttpContext): Observable<void> {
    return this.createManualQaChecks$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `setQaInfo()` */
  static readonly SetQaInfoPath = '/api/v1/QaCheck/{productionOrderId}/SetQaInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setQaInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setQaInfo$Response(params: SetQaInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setQaInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setQaInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setQaInfo(params: SetQaInfo$Params, context?: HttpContext): Observable<void> {
    return this.setQaInfo$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `createSamplingDataForProducedMaterial()` */
  static readonly CreateSamplingDataForProducedMaterialPath =
    '/api/v1/QaCheck/{workCenterId}/CreateSamplingDataForProducedMaterial/{producedMaterialId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSamplingDataForProducedMaterial()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSamplingDataForProducedMaterial$Response(
    params: CreateSamplingDataForProducedMaterial$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return createSamplingDataForProducedMaterial(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSamplingDataForProducedMaterial$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSamplingDataForProducedMaterial(params: CreateSamplingDataForProducedMaterial$Params, context?: HttpContext): Observable<void> {
    return this.createSamplingDataForProducedMaterial$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `createSamplingDataForRun()` */
  static readonly CreateSamplingDataForRunPath = '/api/v1/QaCheck/{workCenterId}/CreateSamplingDataForRun/{runId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSamplingDataForRun()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSamplingDataForRun$Response(params: CreateSamplingDataForRun$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createSamplingDataForRun(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSamplingDataForRun$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSamplingDataForRun(params: CreateSamplingDataForRun$Params, context?: HttpContext): Observable<void> {
    return this.createSamplingDataForRun$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getSetupProductionQaChecksAll()` */
  static readonly GetSetupProductionQaChecksAllPath = '/api/v1/QaCheck/GetForSetup/{productionOrderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSetupProductionQaChecksAll()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getSetupProductionQaChecksAll$Response(
    params: GetSetupProductionQaChecksAll$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ProductionQaCheck>>> {
    return getSetupProductionQaChecksAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSetupProductionQaChecksAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getSetupProductionQaChecksAll(params: GetSetupProductionQaChecksAll$Params, context?: HttpContext): Observable<Array<ProductionQaCheck>> {
    return this.getSetupProductionQaChecksAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductionQaCheck>>): Array<ProductionQaCheck> => r.body)
    );
  }

  /** Path part for operation `getQaCheckerHeader()` */
  static readonly GetQaCheckerHeaderPath = '/api/v1/QaCheck/{producedMaterialId}/GetHeaderForProducedMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQaCheckerHeader()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQaCheckerHeader$Response(params: GetQaCheckerHeader$Params, context?: HttpContext): Observable<StrictHttpResponse<QaCheckHeaderInfo>> {
    return getQaCheckerHeader(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQaCheckerHeader$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQaCheckerHeader(params: GetQaCheckerHeader$Params, context?: HttpContext): Observable<QaCheckHeaderInfo> {
    return this.getQaCheckerHeader$Response(params, context).pipe(
      map((r: StrictHttpResponse<QaCheckHeaderInfo>): QaCheckHeaderInfo => r.body)
    );
  }

  /** Path part for operation `getQaChecksForContainer()` */
  static readonly GetQaChecksForContainerPath = '/api/v1/QaCheck/{productionOrderId}/GetContainerQaCheck';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQaChecksForContainer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQaChecksForContainer$Response(
    params: GetQaChecksForContainer$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<QaCheck>>> {
    return getQaChecksForContainer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQaChecksForContainer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQaChecksForContainer(params: GetQaChecksForContainer$Params, context?: HttpContext): Observable<Array<QaCheck>> {
    return this.getQaChecksForContainer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<QaCheck>>): Array<QaCheck> => r.body)
    );
  }

  /** Path part for operation `getAllQaChecks()` */
  static readonly GetAllQaChecksPath = '/api/v1/QaCheck/{productionOrderId}/GetAllQaChecks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllQaChecks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllQaChecks$Response(params: GetAllQaChecks$Params, context?: HttpContext): Observable<StrictHttpResponse<AllProductionQaChecks>> {
    return getAllQaChecks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllQaChecks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllQaChecks(params: GetAllQaChecks$Params, context?: HttpContext): Observable<AllProductionQaChecks> {
    return this.getAllQaChecks$Response(params, context).pipe(
      map((r: StrictHttpResponse<AllProductionQaChecks>): AllProductionQaChecks => r.body)
    );
  }

  /** Path part for operation `getAllQaChecksFromPreviousOrders()` */
  static readonly GetAllQaChecksFromPreviousOrdersPath = '/api/v1/QaCheck/{productionOrderId}/GetAllQaChecksFromPreviousOrders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllQaChecksFromPreviousOrders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllQaChecksFromPreviousOrders$Response(
    params: GetAllQaChecksFromPreviousOrders$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<AllProductionQaChecksFromPreviousOrder>> {
    return getAllQaChecksFromPreviousOrders(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllQaChecksFromPreviousOrders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllQaChecksFromPreviousOrders(
    params: GetAllQaChecksFromPreviousOrders$Params,
    context?: HttpContext
  ): Observable<AllProductionQaChecksFromPreviousOrder> {
    return this.getAllQaChecksFromPreviousOrders$Response(params, context).pipe(
      map((r: StrictHttpResponse<AllProductionQaChecksFromPreviousOrder>): AllProductionQaChecksFromPreviousOrder => r.body)
    );
  }

  /** Path part for operation `getManualProductionQaChecks()` */
  static readonly GetManualProductionQaChecksPath = '/api/v1/QaCheck/{productionOrderId}/GetManualProductionQaChecks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManualProductionQaChecks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualProductionQaChecks$Response(
    params: GetManualProductionQaChecks$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<PeriodicQaCheck>> {
    return getManualProductionQaChecks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getManualProductionQaChecks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManualProductionQaChecks(params: GetManualProductionQaChecks$Params, context?: HttpContext): Observable<PeriodicQaCheck> {
    return this.getManualProductionQaChecks$Response(params, context).pipe(
      map((r: StrictHttpResponse<PeriodicQaCheck>): PeriodicQaCheck => r.body)
    );
  }

  /** Path part for operation `getActivePeriodicCheck()` */
  static readonly GetActivePeriodicCheckPath = '/api/v1/QaCheck/{productionOrderId}/GetActivePeriodicCheck';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActivePeriodicCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivePeriodicCheck$Response(
    params: GetActivePeriodicCheck$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<PeriodicQaCheck>> {
    return getActivePeriodicCheck(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActivePeriodicCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActivePeriodicCheck(params: GetActivePeriodicCheck$Params, context?: HttpContext): Observable<PeriodicQaCheck> {
    return this.getActivePeriodicCheck$Response(params, context).pipe(
      map((r: StrictHttpResponse<PeriodicQaCheck>): PeriodicQaCheck => r.body)
    );
  }

  /** Path part for operation `getSetupProductionQaChecks()` */
  static readonly GetSetupProductionQaChecksPath = '/api/v1/QaCheck/{productionOrderId}/GetSetupProductionQaChecks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSetupProductionQaChecks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetupProductionQaChecks$Response(
    params: GetSetupProductionQaChecks$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ProductionQaCheck>>> {
    return getSetupProductionQaChecks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSetupProductionQaChecks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetupProductionQaChecks(params: GetSetupProductionQaChecks$Params, context?: HttpContext): Observable<Array<ProductionQaCheck>> {
    return this.getSetupProductionQaChecks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductionQaCheck>>): Array<ProductionQaCheck> => r.body)
    );
  }

  /** Path part for operation `getContainerProductionQaChecks()` */
  static readonly GetContainerProductionQaChecksPath = '/api/v1/QaCheck/{containerId}/GetContainerProductionQaChecks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContainerProductionQaChecks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContainerProductionQaChecks$Response(
    params: GetContainerProductionQaChecks$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ProductionQaCheck>>> {
    return getContainerProductionQaChecks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContainerProductionQaChecks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContainerProductionQaChecks(
    params: GetContainerProductionQaChecks$Params,
    context?: HttpContext
  ): Observable<Array<ProductionQaCheck>> {
    return this.getContainerProductionQaChecks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductionQaCheck>>): Array<ProductionQaCheck> => r.body)
    );
  }

  /** Path part for operation `getLineClearanceQaChecks()` */
  static readonly GetLineClearanceQaChecksPath = '/api/v1/QaCheck/{productionOrderId}/GetLineClearanceQaChecks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLineClearanceQaChecks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLineClearanceQaChecks$Response(
    params: GetLineClearanceQaChecks$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ProductionQaCheck>>> {
    return getLineClearanceQaChecks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLineClearanceQaChecks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLineClearanceQaChecks(params: GetLineClearanceQaChecks$Params, context?: HttpContext): Observable<Array<ProductionQaCheck>> {
    return this.getLineClearanceQaChecks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductionQaCheck>>): Array<ProductionQaCheck> => r.body)
    );
  }

  /** Path part for operation `getSamplingData()` */
  static readonly GetSamplingDataPath = '/api/v1/QaCheck/{productionOrderId}/GetSamplingData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSamplingData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSamplingData$Response(
    params: GetSamplingData$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SamplingDataListViewModel>>> {
    return getSamplingData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSamplingData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSamplingData(params: GetSamplingData$Params, context?: HttpContext): Observable<Array<SamplingDataListViewModel>> {
    return this.getSamplingData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SamplingDataListViewModel>>): Array<SamplingDataListViewModel> => r.body)
    );
  }
}
