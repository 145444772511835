/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getMaterialAvailableAtPileTurner } from '../fn/pile-turner/get-material-available-at-pile-turner';
import { GetMaterialAvailableAtPileTurner$Params } from '../fn/pile-turner/get-material-available-at-pile-turner';
import { getPalletList } from '../fn/pile-turner/get-pallet-list';
import { GetPalletList$Params } from '../fn/pile-turner/get-pallet-list';
import { getPileTurner } from '../fn/pile-turner/get-pile-turner';
import { GetPileTurner$Params } from '../fn/pile-turner/get-pile-turner';
import { getPileTurnerWorkCenterList } from '../fn/pile-turner/get-pile-turner-work-center-list';
import { GetPileTurnerWorkCenterList$Params } from '../fn/pile-turner/get-pile-turner-work-center-list';
import { MaterialAvailableAtLocation } from '../models/material-available-at-location';
import { PileTurner } from '../models/pile-turner';
import { PileTurnerPalletList } from '../models/pile-turner-pallet-list';
import { pileTurnerScanContainer } from '../fn/pile-turner/pile-turner-scan-container';
import { PileTurnerScanContainer$Params } from '../fn/pile-turner/pile-turner-scan-container';
import { PileTurnerScanContainerResponse } from '../models/pile-turner-scan-container-response';
import { PileTurnerScanResult } from '../models/pile-turner-scan-result';
import { RelatedWorkCenter } from '../models/related-work-center';
import { scanPrimaryContainer } from '../fn/pile-turner/scan-primary-container';
import { ScanPrimaryContainer$Params } from '../fn/pile-turner/scan-primary-container';

@Injectable({ providedIn: 'root' })
export class PileTurnerService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pileTurnerScanContainer()` */
  static readonly PileTurnerScanContainerPath = '/api/v1/PileTurner/{pileTurnerWorkCenterId}/ScanContainer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pileTurnerScanContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pileTurnerScanContainer$Response(
    params: PileTurnerScanContainer$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<PileTurnerScanContainerResponse>> {
    return pileTurnerScanContainer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pileTurnerScanContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pileTurnerScanContainer(params: PileTurnerScanContainer$Params, context?: HttpContext): Observable<PileTurnerScanContainerResponse> {
    return this.pileTurnerScanContainer$Response(params, context).pipe(
      map((r: StrictHttpResponse<PileTurnerScanContainerResponse>): PileTurnerScanContainerResponse => r.body)
    );
  }

  /** Path part for operation `getPileTurnerWorkCenterList()` */
  static readonly GetPileTurnerWorkCenterListPath = '/api/v1/PileTurner/GetPileTurnerWorkCenterList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPileTurnerWorkCenterList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPileTurnerWorkCenterList$Response(
    params?: GetPileTurnerWorkCenterList$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<RelatedWorkCenter>>> {
    return getPileTurnerWorkCenterList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPileTurnerWorkCenterList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPileTurnerWorkCenterList(params?: GetPileTurnerWorkCenterList$Params, context?: HttpContext): Observable<Array<RelatedWorkCenter>> {
    return this.getPileTurnerWorkCenterList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RelatedWorkCenter>>): Array<RelatedWorkCenter> => r.body)
    );
  }

  /** Path part for operation `getPileTurner()` */
  static readonly GetPileTurnerPath = '/api/v1/PileTurner/{pileTurnerWorkCenterId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPileTurner()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPileTurner$Response(params: GetPileTurner$Params, context?: HttpContext): Observable<StrictHttpResponse<PileTurner>> {
    return getPileTurner(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPileTurner$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPileTurner(params: GetPileTurner$Params, context?: HttpContext): Observable<PileTurner> {
    return this.getPileTurner$Response(params, context).pipe(map((r: StrictHttpResponse<PileTurner>): PileTurner => r.body));
  }

  /** Path part for operation `getMaterialAvailableAtPileTurner()` */
  static readonly GetMaterialAvailableAtPileTurnerPath = '/api/v1/PileTurner/{workCenterId}/MaterialAvailableAtPileTurner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMaterialAvailableAtPileTurner()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableAtPileTurner$Response(
    params: GetMaterialAvailableAtPileTurner$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<MaterialAvailableAtLocation>> {
    return getMaterialAvailableAtPileTurner(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMaterialAvailableAtPileTurner$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMaterialAvailableAtPileTurner(
    params: GetMaterialAvailableAtPileTurner$Params,
    context?: HttpContext
  ): Observable<MaterialAvailableAtLocation> {
    return this.getMaterialAvailableAtPileTurner$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaterialAvailableAtLocation>): MaterialAvailableAtLocation => r.body)
    );
  }

  /** Path part for operation `scanPrimaryContainer()` */
  static readonly ScanPrimaryContainerPath = '/api/v1/PileTurner/{pileTurnerWorkCenterId}/Scan';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanPrimaryContainer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scanPrimaryContainer$Response(
    params: ScanPrimaryContainer$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<PileTurnerScanResult>> {
    return scanPrimaryContainer(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `scanPrimaryContainer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scanPrimaryContainer(params: ScanPrimaryContainer$Params, context?: HttpContext): Observable<PileTurnerScanResult> {
    return this.scanPrimaryContainer$Response(params, context).pipe(
      map((r: StrictHttpResponse<PileTurnerScanResult>): PileTurnerScanResult => r.body)
    );
  }

  /** Path part for operation `getPalletList()` */
  static readonly GetPalletListPath = '/api/v1/PileTurner/{workCenterId}/PalletList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPalletList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPalletList$Response(params: GetPalletList$Params, context?: HttpContext): Observable<StrictHttpResponse<PileTurnerPalletList>> {
    return getPalletList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPalletList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPalletList(params: GetPalletList$Params, context?: HttpContext): Observable<PileTurnerPalletList> {
    return this.getPalletList$Response(params, context).pipe(
      map((r: StrictHttpResponse<PileTurnerPalletList>): PileTurnerPalletList => r.body)
    );
  }
}
