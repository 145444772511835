/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { CoreApiConfiguration } from '../core-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BobbinQuantityViewModel } from '../models/bobbin-quantity-view-model';
import { getBobbinQuantity } from '../fn/setup-parameter/get-bobbin-quantity';
import { GetBobbinQuantity$Params } from '../fn/setup-parameter/get-bobbin-quantity';
import { getQuantityPerPallet } from '../fn/setup-parameter/get-quantity-per-pallet';
import { GetQuantityPerPallet$Params } from '../fn/setup-parameter/get-quantity-per-pallet';
import { getSetupParameterKinds } from '../fn/setup-parameter/get-setup-parameter-kinds';
import { GetSetupParameterKinds$Params } from '../fn/setup-parameter/get-setup-parameter-kinds';
import { getSetupParameters } from '../fn/setup-parameter/get-setup-parameters';
import { GetSetupParameters$Params } from '../fn/setup-parameter/get-setup-parameters';
import { putSetupParameter } from '../fn/setup-parameter/put-setup-parameter';
import { PutSetupParameter$Params } from '../fn/setup-parameter/put-setup-parameter';
import { putSetupParameterKind } from '../fn/setup-parameter/put-setup-parameter-kind';
import { PutSetupParameterKind$Params } from '../fn/setup-parameter/put-setup-parameter-kind';
import { QuantityPerPalletViewModel } from '../models/quantity-per-pallet-view-model';
import { SetupParameterKind } from '../models/setup-parameter-kind';
import { SetupParameters } from '../models/setup-parameters';

@Injectable({ providedIn: 'root' })
export class SetupParameterService extends BaseService {
  constructor(config: CoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `putSetupParameterKind()` */
  static readonly PutSetupParameterKindPath = '/api/v1/SetupParameter/SetupParameterKind/{productionOrderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putSetupParameterKind()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putSetupParameterKind$Response(params: PutSetupParameterKind$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putSetupParameterKind(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putSetupParameterKind$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putSetupParameterKind(params: PutSetupParameterKind$Params, context?: HttpContext): Observable<void> {
    return this.putSetupParameterKind$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `putSetupParameter()` */
  static readonly PutSetupParameterPath = '/api/v1/SetupParameter/SetupParameter/{productionOrderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putSetupParameter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putSetupParameter$Response(params: PutSetupParameter$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return putSetupParameter(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putSetupParameter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putSetupParameter(params: PutSetupParameter$Params, context?: HttpContext): Observable<void> {
    return this.putSetupParameter$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `getSetupParameterKinds()` */
  static readonly GetSetupParameterKindsPath = '/api/v1/SetupParameter/GetSetupParameterKinds/{productionOrderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSetupParameterKinds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetupParameterKinds$Response(
    params: GetSetupParameterKinds$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<SetupParameterKind>> {
    return getSetupParameterKinds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSetupParameterKinds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetupParameterKinds(params: GetSetupParameterKinds$Params, context?: HttpContext): Observable<SetupParameterKind> {
    return this.getSetupParameterKinds$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetupParameterKind>): SetupParameterKind => r.body)
    );
  }

  /** Path part for operation `getSetupParameters()` */
  static readonly GetSetupParametersPath = '/api/v1/SetupParameter/GetSetupParameters/{productionOrderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSetupParameters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetupParameters$Response(params: GetSetupParameters$Params, context?: HttpContext): Observable<StrictHttpResponse<SetupParameters>> {
    return getSetupParameters(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSetupParameters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetupParameters(params: GetSetupParameters$Params, context?: HttpContext): Observable<SetupParameters> {
    return this.getSetupParameters$Response(params, context).pipe(map((r: StrictHttpResponse<SetupParameters>): SetupParameters => r.body));
  }

  /** Path part for operation `getQuantityPerPallet()` */
  static readonly GetQuantityPerPalletPath = '/api/v1/SetupParameter/GetQuantityPerPallet/{productionOrderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuantityPerPallet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuantityPerPallet$Response(
    params: GetQuantityPerPallet$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<QuantityPerPalletViewModel>> {
    return getQuantityPerPallet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuantityPerPallet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuantityPerPallet(params: GetQuantityPerPallet$Params, context?: HttpContext): Observable<QuantityPerPalletViewModel> {
    return this.getQuantityPerPallet$Response(params, context).pipe(
      map((r: StrictHttpResponse<QuantityPerPalletViewModel>): QuantityPerPalletViewModel => r.body)
    );
  }

  /** Path part for operation `getBobbinQuantity()` */
  static readonly GetBobbinQuantityPath = '/api/v1/SetupParameter/GetBobbinQuantity/{productionOrderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBobbinQuantity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBobbinQuantity$Response(
    params: GetBobbinQuantity$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<BobbinQuantityViewModel>> {
    return getBobbinQuantity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBobbinQuantity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBobbinQuantity(params: GetBobbinQuantity$Params, context?: HttpContext): Observable<BobbinQuantityViewModel> {
    return this.getBobbinQuantity$Response(params, context).pipe(
      map((r: StrictHttpResponse<BobbinQuantityViewModel>): BobbinQuantityViewModel => r.body)
    );
  }
}
